import SkinDisplay from "../../components/SkinDisplay";

export default function SkinInformationSummary({ ownedSkins }) {
	return (
		<div className="my-10 flex flex-col gap-y-10">
			<h1 className="text-xl md:text-3xl text-left font-bold text-blacktext">
				Skins
			</h1>
			<div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-0.5">
				{ownedSkins.map((skin) => {
					return (
						<SkinDisplay
							key={skin.uuid}
							name={skin.displayName}
							imageSrc={skin.imageSrc}
							onClick={null}
						/>
					);
				})}
			</div>
		</div>
	);
}
