import FilterBar from "../FilterBar/FilterBar";
import Popup from "./Popup";
import { useState } from "react";

export default function Filter({ ...props }) {
	const [filterPopup, setFilterPopup] = useState(false);

	return (
		<div className="">
			{filterPopup && (
				<Popup showPopup={filterPopup} setPopup={setFilterPopup}>
					<div className="h-full w-full overflow-y-auto">
						<FilterBar {...props} popup={true} />
					</div>
				</Popup>
			)}
			<button
				onClick={() => setFilterPopup(true)}
				className="flex justify-center items-center"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-10 w-10"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
					strokeWidth={2}
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
					/>
				</svg>
				<h1 className="text-3xl font-semibold">Filter</h1>
			</button>
		</div>
	);
}
