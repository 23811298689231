import { TextField } from "@mui/material";

export default function SellerInformation({
	name,
	setName,
	email,
	setEmail,
	whatsapp,
	setWhatsapp,
	lineID,
	setLineID,
	bca,
	setBca,
}) {
	return (
		<div className="border-[2px] border-valogray shadow-lg p-5 sm:p-10 rounded-xl flex flex-col gap-y-5">
			<h1 className="mb-5 text-xl md:text-3xl text-center font-bold text-blacktext">
				Informasi Penjual
			</h1>
			<div className="w-full flex flex-col gap-y-7">
				<div className="flex flex-col sm:flex-row gap-x-10 gap-y-7">
					<div className="w-full sm:w-1/2">
						<TextField
							fullWidth
							name="name"
							label="Nama"
							variant="filled"
							required={true}
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
					<div className="w-full sm:w-1/2">
						<TextField
							fullWidth
							name="email"
							label="Email"
							variant="filled"
							required={true}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
				</div>
				<div className="flex flex-col sm:flex-row gap-x-10 gap-y-7">
					<div className="w-full sm:w-1/2">
						<TextField
							fullWidth
							name="phone"
							label="No. Whatsapp"
							variant="filled"
							value={whatsapp}
							onChange={(e) => setWhatsapp(e.target.value)}
						/>
					</div>
					<div className="w-full sm:w-1/2">
						<TextField
							fullWidth
							name="line"
							label="Line ID"
							required={true}
							variant="filled"
							value={lineID}
							onChange={(e) => setLineID(e.target.value)}
						/>
					</div>
				</div>
				<div className="flex flex-col sm:flex-row gap-x-10 gap-y-7">
					<div className="w-full sm:w-1/2">
						<TextField
							fullWidth
							name="rekening"
							label="Nomor Rekening"
							required={true}
							variant="filled"
							value={bca}
							onChange={(e) => setBca(e.target.value)}
						/>
					</div>
					<div className="w-full sm:w-1/2" />
				</div>
			</div>
		</div>
	);
}
