import Button from "../../components/Button/RedButton";
import { TextField } from "@mui/material";
import { axios } from "../../constants";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function Find() {
	const navigate = useNavigate();
	const [password, setPassword] = useState("");

	const handleSubmit = async () => {
		let valid = true;
		const resp = await toast.promise(
			axios.post("get_listing_edit_page/", {
				password: password,
			}),
			{
				loading: "Loading...",
				success: "Anda akan segera diarahkan ke laman edit produk",
				error: (err) => {
					valid = false;
					return "Password yang Anda masukkan salah";
				},
			}
		);
		if (valid) {
			setTimeout(() => navigate(resp.data.data), 1500);
		}
	};

	return (
		<div className="my-20 w-full flex items-center justify-center">
			<div className="w-[500px] relative p-8 flex flex-col gap-y-10 items-center justify-center">
				<TextField
					fullWidth
					name="password"
					variant="filled"
					color="error"
					label="Masukkan password edit produk"
					value={password}
					onChange={(e) => setPassword(e.target.value.trim())}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							handleSubmit();
						}
					}}
				/>
				<div className="w-40">
					<Button
						label="Cari"
						fit={true}
						height={50}
						onClick={handleSubmit}
						rightIcon={
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								strokeWidth={2}
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
								/>
							</svg>
						}
					/>
				</div>
			</div>
		</div>
	);
}

export default Find;
