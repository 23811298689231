function Loading() {
	return (
		<div>
			<div className="flex w-full h-[250px] bg-valoblack rounded-xl p-4 text-valowhite my-3">
				<div className="w-1/3 h-full flex items-center">
					<div className="w-full h-full rounded-xl bg-slate-400 animate-pulse" />
				</div>
				<div className="ml-3 w-1/2 flex items-center">
					<div className="w-full flex flex-col gap-y-3 items-center">
						<div className="w-full h-6 bg-slate-400 animate-pulse rounded-xl" />
						<div className="w-full h-6 bg-slate-400 animate-pulse rounded-xl" />
						<div className="w-full h-6 bg-slate-400 animate-pulse rounded-xl" />
						<div className="w-full h-6 bg-slate-400 animate-pulse rounded-xl" />
						<div className="w-full h-6 bg-slate-400 animate-pulse rounded-xl" />
						<div className="w-full h-6 bg-slate-400 animate-pulse rounded-xl" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default Loading;
