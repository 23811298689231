import { axios, axiosWithCredentials } from "../../../constants";

import Button from "../../../components/Button/RedButton";
import { useState } from "react";

function DoneButton({ listing, screenshotId, line, params, setDone, status }) {
	const [loading, setLoading] = useState(false);

	const handleSubmit = async () => {
		setLoading(true);
		await axiosWithCredentials.post(`upload_datas/${params.listing_id}/`, {
			line: line,
			payment_id: screenshotId,
		});
		await axios.post("create_linebuysession/", {
			buyerLineId: line,
			sellerLineId: listing.data.data.line,
		});
		setLoading(false);
	};

	return (
		<div className="w-4/5 max-w-[300px] mb-10 text-sm md:text-base">
			<Button
				label="Selesaikan Pembayaran"
				fit={true}
				onClick={async () => {
					await handleSubmit();
					status.refetch();
					setDone(true);
				}}
				onSuccess="Pembayaran Anda akan segera dikonfirmasi admin"
				isLoading={loading}
				height={50}
				disabled={screenshotId === null || line === ""}
			/>
		</div>
	);
}

export default DoneButton;
