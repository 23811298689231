import Button from "../../components/Button/RedButton";
import { useNavigate } from "react-router-dom";

function Index() {
	const navigate = useNavigate();

	return (
		<>
			<div className="my-20 flex items-center justify-center">
				<div className="w-full flex flex-col items-center sm:w-4/5 md:w-2/3 lg:w-1/4">
					<img
						src="/jettkawai.png"
						alt="denied"
						className="w-full rounded-xl"
					/>
					<h1 className="w-full text-center text-2xl font-semibold">
						404 Page Not Found
					</h1>
					<div className="mt-5 w-1/2">
						<Button
							label="Home"
							onClick={() => navigate("/")}
							fit={true}
							height={50}
						/>
					</div>
				</div>
			</div>
		</>
	);
}

export default Index;
