import Select from "react-select";

export default function MinRank({
	minRankPlaceholder,
	setMinRankPlaceholder,
	rankList,
	searchParams,
	setSearchParams,
}) {
	const additional_ranks = rankList.data.data.data.map((rank) => {
		return {
			value: rank.tier,
			label: rank.tierName,
			imageSrc: rank.imageSrc,
		};
	});
	const ranks = [
		{ value: null, label: "Rank Minimum", imageSrc: null },
		...additional_ranks,
	];

	return (
		<div className="w-full">
			<Select
				options={ranks}
				value={ranks.filter(
					(rank) => rank.value === parseInt(searchParams.get("min_rank"))
				)}
				onChange={(e) => {
					setSearchParams({
						...Object.fromEntries([...searchParams]),
						min_rank: e.value,
					});
				}}
				placeholder="Rank Minimum"
				className="w-full"
				formatOptionLabel={(rank) => (
					<div className={`w-full flex ${rank.imageSrc && "justify-center"}`}>
						{rank.imageSrc && (
							<img src={rank.imageSrc} alt="rankImage" className="w-7 h-7" />
						)}
						{!rank.imageSrc && <h1 className="text-valogray">{rank.label}</h1>}
					</div>
				)}
			/>
		</div>
	);
}
