import Loading from "./Loading";
import SkinDisplay from "../../SkinDisplay/SkinDisplay";

export default function AllSkin({
	navigate,
	listing,
	setVideoPopup,
	setVideoSrc,
}) {
	if (listing.isLoading) return <Loading />;
	if (listing.isError) navigate(`/error?err=${listing.error.message}`);

	return (
		<div className="grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 hidden lg:grid">
			{listing.data.data.skins.map((skin) => {
				return (
					<SkinDisplay
						key={skin.uuid}
						skin={skin}
						setVideoPopup={setVideoPopup}
						setVideoSrc={setVideoSrc}
					/>
				);
			})}
		</div>
	);
}
