import { useEffect, useState } from "react";

import Button from "../../../Button/RedButton";
import Popup from "../../../Popup/Popup";
import SecondaryButton from "../../../Button/SecondaryButton";
import TextInput from "../../../Input/TextInput";
import toast from "react-hot-toast";

function reverseString(str) {
	let splitString = str.split("");
	let reverseArray = splitString.reverse();
	let joinArray = reverseArray.join("");
	return joinArray;
}

export function Reformat(price) {
	if (price === 0) {
		return "0";
	}
	let totalPriceDigits = price.toString().split("");
	totalPriceDigits = totalPriceDigits.reverse();

	let totalPriceInRupiah = "";
	for (let i = 0; i < totalPriceDigits.length; i++) {
		totalPriceInRupiah += totalPriceDigits[i];
		if (i === totalPriceDigits.length - 1) continue;
		if ((i + 1) % 3 === 0) totalPriceInRupiah += ".";
	}
	totalPriceInRupiah = reverseString(totalPriceInRupiah);

	return totalPriceInRupiah;
}

export function Deformat(price) {
	if (price === null) return 0;
	let priceStr = price.toString();
	let ret = 0;
	for (let i = 0; i < priceStr.length; i++) {
		if (priceStr[i] === ".") continue;
		if (priceStr[i] < "0" || priceStr[i] > "9") continue;
		ret += parseInt(priceStr[i]);
		ret *= 10;
	}
	ret /= 10;
	return ret;
}

export default function Price({
	totalPrice,
	discount,
	setDiscount,
	showChangeDiscountPopup,
	setShowChangeDiscountPopup,
	initialDiscount,
	create,
	update,
}) {
	let totalPriceDigits = totalPrice.toString().split("");
	totalPriceDigits = totalPriceDigits.reverse();

	let totalPriceInRupiah = "";
	for (let i = 0; i < totalPriceDigits.length; i++) {
		totalPriceInRupiah += totalPriceDigits[i];
		if (i === totalPriceDigits.length - 1) continue;
	}
	totalPriceInRupiah = parseInt(reverseString(totalPriceInRupiah));

	return (
		<>
			<Popup
				showPopup={showChangeDiscountPopup}
				setShowPopup={setShowChangeDiscountPopup}
			>
				<SetDiscount
					totalPriceInRupiah={totalPriceInRupiah}
					discount={discount}
					setDiscount={setDiscount}
					setShowChangeDiscountPopup={setShowChangeDiscountPopup}
					initialDiscount={initialDiscount}
					create={create}
					update={update}
				/>
			</Popup>
			<div className="my-10 flex flex-col gap-y-5 w-full justify-center">
				<h1 className="text-xl md:text-3xl text-left font-bold text-blacktext">
					Ringkasan Harga
				</h1>
				<div className="w-full flex flex-col gap-y-5 text-base md:text-[20px] font-normal">
					<div className="w-full flex">
						<h1 className="w-3/4">Total Harga</h1>
						<h1 className="flex justify-end w-1/4">
							Rp{Reformat(totalPriceInRupiah)}
						</h1>
					</div>
					<div className="w-full flex">
						<div className="relative flex items-center w-1/4">
							<div className="group flex gap-x-3 items-center">
								Diskon
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-6 w-6"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									strokeWidth={2}
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
									/>
								</svg>
								<div className="hidden group-hover:inline-flex left-[100px] top-0 absolute w-[200px] sm:w-[300px] h-32 text-sm sm:text-base text-center text-valowhite2 bg-redButton rounded-3xl font-medium p-10 items-center">
									Berikan potongan harga agar akun Anda masuk ke beranda website
									kami
								</div>
							</div>
						</div>
						<div className="flex gap-x-5 justify-end w-3/4">
							<div className="flex flex-row items-center gap-x-2 md:gap-x-5">
								<button
									onClick={() => setShowChangeDiscountPopup(true)}
									className="sm:hidden"
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-6 w-6"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										strokeWidth={2}
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
										/>
									</svg>
								</button>
								<div className="text-base hidden sm:block">
									<SecondaryButton
										label="Ubah Diskon"
										width={150}
										height={30}
										onClick={() => setShowChangeDiscountPopup(true)}
									/>
								</div>
								<h1 className="">-Rp{Reformat(discount)}</h1>
							</div>
						</div>
					</div>
					<div className="w-full flex">
						<h1 className="w-3/4">Pembulatan</h1>
						<h1 className="flex justify-end w-1/4">
							-Rp
							{Reformat(
								Math.max(totalPriceInRupiah - discount, 0) -
									Math.floor(
										Math.max(totalPriceInRupiah - discount, 0) / 1000
									) *
										1000
							)}
						</h1>
					</div>
					<hr className="w-full h-1 bg-valogray my-5" />
					<div className="w-full flex">
						<h1 className="w-3/4 font-semibold text-sm md:text-base">
							Uang yang Anda dapat
						</h1>
						<h1 className="flex items-center justify-end w-1/4">
							Rp
							{Reformat(
								Math.floor(Math.max(totalPriceInRupiah - discount, 0) / 1000) *
									1000
							)}
						</h1>
					</div>
				</div>
			</div>
		</>
	);
}

const SetDiscount = ({
	initialDiscount,
	totalPriceInRupiah,
	discount,
	setDiscount,
	setShowChangeDiscountPopup,
	create,
	update,
}) => {
	const [discountPlaceholder, setDiscountPlaceholer] = useState(
		"Rp" + Reformat(discount)
	);

	useEffect(() => {
		if (totalPriceInRupiah - Deformat(discountPlaceholder) < 0) {
			setDiscountPlaceholer(Reformat(Deformat(totalPriceInRupiah)));
		}
	}, [
		discountPlaceholder,
		setDiscountPlaceholer,
		totalPriceInRupiah,
		initialDiscount,
	]);

	const handleClick = () => {
		if (update) {
			if (Deformat(discountPlaceholder) < initialDiscount + 10000) {
				toast.error("Penambahan diskon minimal adalah Rp10.000");
			} else if (totalPriceInRupiah - Deformat(discountPlaceholder) < 0) {
				toast.error(
					`Penambahan diskon maksimal adalah Rp${Reformat(totalPriceInRupiah)}`
				);
			} else {
				setDiscount(Deformat(discountPlaceholder));
				setShowChangeDiscountPopup(false);
			}
		}
		if (create) {
			if (totalPriceInRupiah - Deformat(discountPlaceholder) < 0) {
				toast.error(
					`Penambahan diskon maksimal adalah Rp${Reformat(totalPriceInRupiah)}`
				);
			} else {
				setDiscount(Deformat(discountPlaceholder));
				setShowChangeDiscountPopup(false);
			}
		}
	};

	return (
		<div className="w-72 h-60 pb-10 flex flex-col justify-between items-center text-base">
			<div className="w-3/4 text-valoblack">
				<TextInput
					autoFocus
					label="Diskon"
					value={discountPlaceholder}
					setValue={(x) => setDiscountPlaceholer("Rp" + Reformat(Deformat(x)))}
					fit={true}
					onEnter={handleClick}
				/>
			</div>
			<h1>
				Harga Akhir Rp
				{Reformat(
					Math.floor(
						Math.max(totalPriceInRupiah - Deformat(discountPlaceholder), 0) /
							1000
					) * 1000
				)}
			</h1>
			<div className="w-1/2 text-sm">
				<Button
					label="Simpan Diskon"
					onClick={handleClick}
					fit={true}
					height={40}
				/>
			</div>
		</div>
	);
};
