import { Box, Modal } from "@mui/material";

import Loading from "./Loading";
import { useState } from "react";
import { useWindowSize } from "../../../hooks/useWindowSize";

function Screenshot({ listing, navigate }) {
	const [open, setOpen] = useState(false);
	const windowSize = useWindowSize();

	if (listing.isLoading) return <Loading />;
	if (listing.isError) navigate(`/error?err=${listing.error.message}`);

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "80%",
		bgcolor: "background.paper",
		boxShadow: 24,
	};

	return (
		<>
			<Modal
				open={open}
				onClose={() => setOpen(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<img
						src={listing.data.data.screenshotImageSrc}
						alt="screenshot"
						className="w-full"
					/>
				</Box>
			</Modal>
			<button
				onClick={() => {
					if (windowSize.width >= 1024) {
						setOpen(true);
					}
				}}
			>
				<img
					src={listing.data.data.screenshotImageSrc}
					alt="screenshot"
					className="w-full"
				/>
			</button>
		</>
	);
}

export default Screenshot;
