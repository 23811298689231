import Loading from "./Loading";

export default function Pagebar({ listingList, params, navigate }) {
	if (listingList.isLoading) return <Loading />;
	if (listingList.isError) navigate(`/error?err=${listingList.error.message}`);

	const pages = [];
	for (let page = 1; page <= listingList.data.data.page_limit; page++) {
		pages.push(page);
	}

	const cur_page = parseInt(
		params.page_number === undefined ? "1" : params.page_number
	);

	return (
		<div className="flex justify-center">
			<button
				className="disabled:opacity-20 w-10 h-10"
				onClick={() => navigate(`/explore/${cur_page - 1}`)}
				disabled={cur_page === 1}
			>
				<div className="flex justify-center items-center">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="h-6 w-6"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						strokeWidth={2}
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M15 19l-7-7 7-7"
						/>
					</svg>
				</div>
			</button>
			{pages.map((page) => {
				return (
					<button
						onClick={() => navigate(`/explore/${page}`)}
						key={page}
						className="w-10 h-10"
					>
						<h1>{page}</h1>
					</button>
				);
			})}
			<button
				disabled={
					cur_page === listingList.data.data.page_limit ||
					listingList.data.data.page_limit === 0
				}
				onClick={() => navigate(`/explore/${cur_page + 1}`)}
				className="disabled:opacity-20 w-10 h-10"
			>
				<div className="flex justify-center items-center">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="h-6 w-6"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						strokeWidth={2}
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M9 5l7 7-7 7"
						/>
					</svg>
				</div>
			</button>
		</div>
	);
}
