function Loading() {
	return (
		<div className="grid gap-x-5 place-content-center grid-cols-1 sm:grid-cols-2">
			{[...Array(8).keys()].map((x) => {
				return (
					<button
						key={x}
						disabled={true}
						className="relative drop-shadow-xl my-5 h-[260px] sm:h-[350px]"
					>
						<div className="w-full h-full bg-slate-400 animate-pulse rounded-xl"></div>
					</button>
				);
			})}
		</div>
	);
}

export default Loading;
