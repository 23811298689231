export default function Level({ listing }) {
	return (
		<>
			{listing.data.data.level !== null && (
				<div className="flex w-full">
					<h1 className="font-semibold">Lv.</h1>
					<h1 className="font-semibold">{listing.data.data.level}</h1>
				</div>
			)}
		</>
	);
}
