export default function Rank({ listing }) {
  return (
    <div className="w-1/3 flex items-center">
      <img
        src={listing.data.data.rank.imageSrc}
        alt="rankImage"
        className="w-full"
      />
    </div>
  );
}
