import { REKENING_OWNER } from "../../constants";
import TwoChoice from "../../components/Button/TwoChoice";
import { useState } from "react";

const Rules = () => {
	const [english, setEnglish] = useState(false);

	return (
		<div className="text-sm sm:text-base md:text-lg flex flex-col gap-y-6 sm:gap-y-10 my-10 mx-10 sm:mx-[100px] md:mx-[200px] lg:mx-[300px] xl:mx-[400px] 2xl:mx-[600px] font-normal">
			<div className="w-full flex justify-center">
				<TwoChoice
					leftLabel="EN"
					rightLabel="ID"
					width={300}
					height={40}
					left={english}
					setLeft={setEnglish}
				/>
			</div>

			<div className="flex flex-col gap-y-1 sm:gap-y-4">
				<h1 className="text-center font-bold text-xl sm:text-3xl">
					{english ? "Trade Terms and Conditions" : "Peraturan Jual Beli"}
				</h1>
				<div className="font-perm-marker text-valored text-center text-xl sm:text-3xl">
					{english ? "TRANSACTION = AGREE" : "TRANSAKSI = SETUJU"}
				</div>
			</div>

			<div className="flex flex-col gap-y-3">
				<h1 className="sm:pb-3 text-base sm:text-lg md:text-xl lg:text-2xl text-center sm:text-left">
					{english ? (
						<strong>User Terms</strong>
					) : (
						<strong>Syarat dan Ketentuan Pengguna</strong>
					)}
				</h1>

				<div className="flex flex-col gap-y-2 sm:gap-y-5">
					{english ? (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">1.</div>
							<div className="w-full">
								Only wire money to a bank account under the name of{" "}
								{REKENING_OWNER}
							</div>
						</div>
					) : (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">1.</div>
							Transfer hanya menuju rekening atas nama {REKENING_OWNER}
						</div>
					)}

					{english ? (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">2.</div>
							Buyer is allowed to issue a refund should the purchased item
							differ from the information listed on the site or the seller not
							responding after 1x24 hours.
						</div>
					) : (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">2.</div>
							Pembeli berhak mengajukan refund jika akun yang dibeli tidak
							sesuai dengan yang tertera di website atau penjual tidak respon
							dalam 1x24 jam.
						</div>
					)}

					{english ? (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">2.</div>
							Buyer has 2x24 hours to confirm the purchase, should the time pass
							the given limit, the purchase is considered done and no complaints
							will be accepted.
						</div>
					) : (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">2.</div>
							Pembeli memiliki waktu 2x24 jam untuk konfirmasi, apabila melewati
							waktu yang telah diberikan, pesanan akan dianggap selesai dan
							segala jenis komplain tidak akan diterima.
						</div>
					)}

					{english ? (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">3.</div>
							No refund will be granted to any bought account under any
							conditions.
						</div>
					) : (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">3.</div>
							Akun yang sudah dibeli tidak dapat dikembalikan dengan alasan
							apapun.
						</div>
					)}

					{english ? (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">4.</div>
							Both parties are STRICTLY PROHIBITED to communicate outside the
							knowledge of Valodeals' admins, Valodeals does not hold any
							responsibility for any fraudery that occurs as a consequence of
							aforementioned actions.
						</div>
					) : (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">4.</div>
							Penjual dan Pembeli DILARANG KERAS berkomunikasi tanpa izin dan
							tanpa sepengetahuan admin Valodeals, apabila dilanggar dan terjadi
							penipuan atau sejenisnya, BUKANLAH tanggung jawab Valodeals.
						</div>
					)}
				</div>
			</div>

			<div className="flex flex-col gap-y-3">
				<h1 className="sm:pb-3 text-base sm:text-lg md:text-xl lg:text-2xl text-center sm:text-left">
					{english ? (
						<strong>Valodeals Rights</strong>
					) : (
						<strong>Hak Valodeals</strong>
					)}
				</h1>

				<div className="flex flex-col gap-y-2 sm:gap-y-5">
					{english ? (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">1.</div>
							Valodeals has the right to increase the price of a listing put by
							a customer as long as the money recieved by the seller is
							equivalent to the one written at the time of listing enrollment.
						</div>
					) : (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">1.</div>
							Dana yang didapatkan penjual adalah harga terakhir yang tertera
							saat pendaftaran akun. Penjual tidak memiliki hak untuk komplain
							mengenai nominal yang tertera pada website atau berapa keuntungan
							yang dibuat pihak Valodeals.
						</div>
					)}

					{english ? (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">2.</div>
							Valodeals has the right to delete a listing if we deem the listing
							to be fake, fraudulent, or deceiving.
						</div>
					) : (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">2.</div>
							Valodeals memiliki hak untuk menghapus akun yang didaftarkan jika
							akun tersebut palsu atau tidak sesuai dengan informasi yang
							dituliskan.
						</div>
					)}

					{english ? (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">3.</div>
							Valodeals has the right to cancel a transaction and refund the
							money to the Buyer if we deem appropriate.
						</div>
					) : (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">3.</div>
							Valodeals memiliki hak untuk membatalkan suatu transaksi dan
							mengembalikan uang kepada pihak pembeli.
						</div>
					)}

					{english ? (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">4.</div>
							<div>
								Valodeals has the right to EXPOSE THE DATA of a Seller if we
								discover fraudulent behaviour that breaks our transactional
								rules like <i>hackback</i> or similar actions that result in the
								loss of access to an account.
							</div>
						</div>
					) : (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">4.</div>
							<div>
								Valodeals memiliki hak untuk MENGEKSPOS DATA penjual apabila
								melanggar aturan yang tertera atau melakukan penipuan seperti{" "}
								<i>hackback</i> dan sejenisnya yang mengakibatkan hilangnya
								akses akun.
							</div>
						</div>
					)}
				</div>
			</div>

			<div className="flex flex-col gap-y-3">
				<h1 className="sm:pb-3 text-base sm:text-lg md:text-xl lg:text-2xl text-center sm:text-left">
					{english ? (
						<strong>Disbursement of Funds</strong>
					) : (
						<strong>Pencairan Dana</strong>
					)}
				</h1>

				<div className="flex flex-col gap-y-2 sm:gap-y-5">
					{english ? (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">1.</div>
							<div>Disbursement of Funds will </div>
						</div>
					) : (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">1.</div>
							<div>
								Dana cair apabila pihak pembeli sudah konfirmasi bahwa transaksi
								aman dan menyelesaikan transaksi.
							</div>
						</div>
					)}

					{english ? (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">2.</div>
							Valodeals is not responsible for any complaints or dissatisfaction
							after the funds has been disbursed.
						</div>
					) : (
						<div className="flex gap-x-3">
							<div className="min-w-[24px] flex justify-center">2.</div>
							Apabila dana sudah cair, segala komplain dan keluhan terkait
							barang yang dibeli atau dijual bukanlah tanggung jawab pihak
							Valodeals.
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Rules;
