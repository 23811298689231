import { useEffect, useState } from "react";

import { Checkbox } from "@mui/material";
import ListingSlider from "../../../ListingSlider/ListingSlider";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../../../../hooks/useWindowSize";

export default function DiscountListings({ discountedListings }) {
	let navigate = useNavigate();
	const [index, setIndex] = useState(0);
	const [showAll, setShowAll] = useState(true);
	let size = useWindowSize();
	let listingPerPage = 1;
	if (size.width >= 768) {
		listingPerPage = 2;
	}
	if (size.width >= 1280) {
		listingPerPage = 3;
	}
	const props = {
		navigate,
		index,
		setIndex,
		size,
		listingPerPage,
	};

	const [listings, setListings] = useState([]);
	useEffect(() => {
		if (!discountedListings.isLoading) {
			if (showAll) {
				setListings(discountedListings.data.data.data);
			} else {
				setListings(
					discountedListings.data.data.data.filter(
						(d) => d.status === "available"
					)
				);
			}
		}
	}, [showAll, discountedListings.isLoading]);

	if (discountedListings.isLoading) return <Loading {...props} />;
	if (discountedListings.isError)
		navigate(`/error?err=${discountedListings.error.message}`);

	let splitListings = [];
	for (let i = 0; i < listings.length; i += listingPerPage) {
		let curListings = [];
		for (let j = i; j < Math.min(i + listingPerPage, listings.length); j++) {
			curListings.push(listings[j]);
		}
		splitListings.push(curListings);
	}

	const label = { inputProps: { "aria-label": "Checkbox demo" } };

	return (
		<div className="bg-discountBg py-5 sm:py-20 flex justify-center">
			<div className="mx-5 sm:mx-20 w-5/6">
				<div className="w-full flex flex-col">
					<div className="flex flex-col sm:flex-row justify-between items-center">
						<h1 className="mb-4 text-xl sm:text-2xl md:text-4xl text-center sm:text-left text-[#E7ECEE] font-black">
							Akun Valorant Diskon
						</h1>
						<div className="flex flex-row items-center gap-x-1 sm:gap-x-3 text-white">
							<h1 className="text-sm sm:text-base font-semibold sm:font-bold">
								Tunjukan Semua Akun
							</h1>
							<div className="w-10">
								<Checkbox
									defaultChecked
									value={showAll}
									onChange={(e) => setShowAll(!showAll)}
									{...label}
									sx={{
										color: "#F84654",
										"&.Mui-checked": {
											color: "#F84654",
										},
									}}
								/>
							</div>
						</div>
					</div>
					<div className="w-full">
						<ListingSlider splitListings={splitListings} />
					</div>
				</div>
			</div>
		</div>
	);
}
