export default function ValorantPoints({ listing }) {
	return (
		<div className="flex items-center w-full">
			<img className="w-4 h-4 sm:h-5 sm:w-5" src="/vp.png" alt="vp" />
			<h1 className="ml-2 font-semibold">
				{listing.data.data.valorant_points === -1
					? 0
					: listing.data.data.valorant_points}
			</h1>
		</div>
	);
}
