import { useEffect, useRef } from "react";

export default function Popup({
	children,
	setSellPopup,
	setSellInfoPopup,
	closable,
}) {
	let popupRef = useRef();
	useEffect(() => {
		if (closable) {
			const handler = (e) => {
				if (popupRef.current && !popupRef.current.contains(e.target)) {
					setSellInfoPopup(false);
					setSellPopup(false);
				}
			};
			document.addEventListener("mousedown", handler);
			return () => {
				document.removeEventListener("mousedown", handler);
			};
		}
	});

	return (
		<div className="z-50 fixed bg-black/50 left-0 right-0 bottom-0 top-0 w-screen h-screen flex justify-center items-center">
			<div
				ref={popupRef}
				className={`rounded-xl absolute bg-popup p-10 flex items-center justify-center w-[90%] sm:w-4/5 max-w-[1000px] max-h-[900px] overflow-auto`}
			>
				<button
					className="absolute right-3 top-3"
					onClick={() => {
						setSellInfoPopup(false);
						setSellPopup(false);
					}}
				>
					{closable && (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-6 w-6 hover:fill-red-500"
							viewBox="0 0 20 20"
							fill="white"
						>
							<path
								fillRule="evenodd"
								d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
								clipRule="evenodd"
							/>
						</svg>
					)}
				</button>
				{children}
			</div>
		</div>
	);
}
