export const trends = [
	{ displayName: "Reaver 2.0", uuid: "f7dcf7e1-485e-0524-ec82-0d97b2c8b40b" },
	{
		displayName: "Prelude to Chaos",
		uuid: "526f7d0c-414b-24bb-bcd0-10aed1f3e824",
	},
	{ displayName: "Reaver", uuid: "81d85522-4651-4f66-72de-5fa057b3514c" },
	{
		displayName: "RGX 11z Pro",
		uuid: "ed453815-44aa-4c4d-f3aa-77b4bcf048d7",
	},
	{
		displayName: "RGX 11z Pro",
		uuid: "d958b181-4e7b-dc60-7c3c-e3a3a376a8d2",
	},
	{ displayName: "Spectrum", uuid: "f7f37856-4af7-9b0e-08aa-91a5207c0439" },
	{ displayName: "Elderflame", uuid: "1ba50cf0-46dd-848f-13a9-dc92fb0a3e3b" },
	{ displayName: "Arcane", uuid: "2270b116-4255-8a14-4486-db9de4979b89" },
	{
		displayName: "Protocol 781-A",
		uuid: "ab83f73d-485f-e010-8ea0-24b538468a1a",
	},
	{ displayName: "Oni", uuid: "ebfb909d-45ba-c514-3369-55bf014ba293" },
	{ displayName: "Ion", uuid: "693d675e-4ed2-c00a-5e38-6b859b275565" },
	{ displayName: "Ruination", uuid: "ae0c9cc4-4c03-f8d6-745c-84953db684fc" },
	{
		displayName: "Gaia's Vengeance",
		uuid: "e10e00ae-4dcc-3c4a-16f7-7e885314f0d0",
	},
	{ displayName: "Prime", uuid: "2116a38e-4b71-f169-0d16-ce9289af4bfa" },
	{
		displayName: "Singularity",
		uuid: "ef72e3c0-467b-ab15-076a-1e9690d16d6f",
	},
	{ displayName: "Glitchpop", uuid: "05e8add9-404d-5d37-8973-9f93f8880e2d" },
	{ displayName: "Glitchpop", uuid: "fc723fef-444a-4013-a741-3e85a97382f2" },
	{
		displayName: "Champions 2021",
		uuid: "bf987f36-4a33-45e4-3c49-1ab9a2502607",
	},
	{ displayName: "Origin", uuid: "54f8793c-4daa-6e45-bcfd-e9bfc742dc30" },
	{ displayName: "BlastX", uuid: "a31f7d1f-89d8-47ae-875b-1ae2117655c2" },
	{
		displayName: "Sentinels of Light",
		uuid: "13d427c1-49d7-44f9-6576-27a9c83d787f",
	},
];
