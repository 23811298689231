function CodeFilter({ codeFilter, setCodeFilter }) {
	return (
		<div className="w-full flex flex-col gap-y-3 justify-start p-6">
			<h1 className="text-md font-medium w-full">Cari kode akun</h1>
			<input
				type="text"
				className="w-full h-10 pl-3 rounded-md placeholder:text-valogray"
				placeholder="Kode Akun"
				onChange={(e) => setCodeFilter(e.target.value)}
				value={codeFilter}
			/>
		</div>
	);
}

export default CodeFilter;
