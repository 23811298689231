import Skeleton from "@mui/material/Skeleton";

export default function RankInput({ curRank, setRank, completeRankList }) {
	return (
		<div className="w-full flex flex-row gap-x-5 overflow-auto py-5">
			{!completeRankList.isLoading ? (
				completeRankList.data.data.data.map((rank) => {
					const hoverChange =
						curRank === "" ? "hover:opacity-30" : "hover:opacity-100";
					return (
						<button
							className="w-16 h-16"
							key={rank.tier}
							onClick={() => setRank(rank)}
						>
							<div className="w-16 h-16">
								<img
									className={`transition-all duration-150 w-16 h-16 ${
										curRank.tierName === rank.tierName && "opacity-100"
									} ${
										curRank.tierName !== rank.tierName &&
										curRank !== "" &&
										"opacity-10"
									} ${hoverChange}`}
									src={rank.imageSrc}
									alt={rank.tierName}
								/>
							</div>
						</button>
					);
				})
			) : (
				<Skeleton variant="rectangular" width="100%" height="64px" />
			)}
		</div>
	);
}
