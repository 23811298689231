import Button from "../../../components/Button/BlueButton";
import Loading from "./Loading";
import VoucherPopup from "./VoucherPopup";
import { axiosWithCredentials } from "../../../constants";
import toast from "react-hot-toast";
import { useState } from "react";
import { useWindowSize } from "../../../hooks/useWindowSize";

function BuyButton({ navigate, params, listing }) {
	const windowSize = useWindowSize();
	const [showModal, setShowModal] = useState(false);

	const handleOpenBuyPage = async (voucher_id) => {
		if (Object.keys(response).includes(listing.data.data.status)) {
			toast.error(response[listing.data.data.status]);
		} else {
			await toast.promise(
				axiosWithCredentials.get(`open_buy_page/${params.listing_id}/`),
				{
					loading: "Loading...",
					success: voucher_id
						? "Voucher Anda berhasil dipakai!"
						: "Silahkan mengakses laman pembayaran",
					error: (err) => {
						return err.message;
					},
				}
			);
			if (voucher_id) {
				setTimeout(
					() => navigate(`/buy/${params.listing_id}?voucher=${voucher_id}/`),
					2000
				);
			} else {
				setTimeout(() => navigate(`/buy/${params.listing_id}/`), 2000);
			}
		}
	};

	if (listing.isLoading) return <Loading />;
	if (listing.isError) navigate(`/404`);

	const response = {
		another_user: "Ada pengguna lain yang sedang menggunakan laman pembelian",
		pending: "Pembayaran akun ini sedang dikonfirmasi admin",
		sold: "Akun ini sudah terjual",
		taken_down: "Akun ini dihapus oleh penjual",
		unavailable: "Akun ini sudah tidak bisa dibeli",
	};

	return (
		<>
			<VoucherPopup
				showModal={showModal}
				setShowModal={setShowModal}
				handleOpenBuyPage={handleOpenBuyPage}
			/>
			<div className="ml-5 lg:ml-0 w-40 sm:w-60 xl:w-80">
				<Button
					leftIcon={
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-6 w-6"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							strokeWidth={2}
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
							/>
						</svg>
					}
					label="Beli"
					disabled={true}
					fit={true}
					height={windowSize.width < 640 ? 45 : 50}
					onClick={async () => setShowModal(true)}
				/>
			</div>
		</>
	);
}

export default BuyButton;
