import { Carousel } from "react-responsive-carousel";
import Container from "./Container";
import Skeleton from "@mui/material/Skeleton";
import { axios } from "../../constants";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useState } from "react";

function Gallery() {
	const contentTiers = useQuery(["contentTiers"], async () => {
		return await axios.get("get_all_content_tiers/");
	});
	const bundles = useQuery(["bundles"], async () => {
		return await axios.get("get_all_bundles/");
	});
	const recent_bundles = useQuery(["recent_bundles"], async () => {
		return await axios.get("get_most_recent_bundles/");
	});

	const [filter, setFilter] = useState("");
	const [rarity, setRarity] = useState(-1);
	const props = {
		filter,
		setFilter,
		rarity,
		setRarity,
		bundles,
		contentTiers,
		recent_bundles,
	};

	return (
		<Container>
			<BundleCarousel {...props} />
			<SearchBar {...props} />
			<RarityFilter {...props} />
			<Bundles {...props} />
		</Container>
	);
}

const BundleCarousel = ({ bundles, contentTiers, recent_bundles }) => {
	const navigate = useNavigate();

	return (
		<div className="w-full max-w-[1200px] flex flex-col gap-y-3 sm:gap-y-10">
			<h1 className="text-center text-xl sm:text-2xl lg:text-4xl xl:text-5xl font-black font-montserrat">
				Bundle Terbaru
			</h1>
			{bundles.isLoading ||
			contentTiers.isLoading ||
			recent_bundles.isLoading ? (
				<div className="w-full h-[250px] sm:h-[350px] md:h-[500px]">
					<Skeleton
						variant="rectangular"
						style={{ borderRadius: "36px" }}
						width="100%"
						height="100%"
					/>
				</div>
			) : (
				<div className="normal-rounded-carousel">
					<Carousel
						autoPlay
						infiniteLoop
						showStatus={false}
						showIndicators={false}
						interval={1500}
						className="w-full rounded-3xl"
						renderThumbs={() => {
							return recent_bundles.data.data.data
								.slice(0, 10)
								.map((bundle) => (
									<img
										key={bundle.uuid}
										src={bundle.imageSrc}
										alt={bundle.displayName}
										className="w-full"
									/>
								));
						}}
					>
						{recent_bundles.data.data.data.slice(0, 10).map((bundle) => {
							const contentTier = contentTiers.data.data.data.find(
								(contentTier) => contentTier.rank === bundle.contentTier
							);
							return (
								<button
									onClick={() => navigate(`/gallery/${bundle.uuid}`)}
									key={bundle.uuid}
									className="w-full relative"
								>
									<img
										src={bundle.imageSrc}
										alt={bundle.displayName}
										className="rounded-3xl w-full h-[250px] sm:h-[350px] md:h-[500px] object-cover"
									/>
									<div className="absolute bottom-0 left-0 rounded-bl-3xl font-bold text-base sm:text-xl md:text-2xl lg:text-3xl text-white px-10 py-2 sm:py-3 lg:py-4 mr-10 bg-black/80 flex flex-row items-center gap-x-5">
										<h1>{bundle.displayName.toUpperCase()}</h1>
										{contentTier.imageSrc && (
											<div className="min-w-[28px] h-7 sm:min-w-[40px] sm:h-10">
												<img
													src={contentTier.imageSrc}
													alt={contentTier.displayName}
													className="w-full h-full"
												/>
											</div>
										)}
									</div>
								</button>
							);
						})}
					</Carousel>
				</div>
			)}
		</div>
	);
};

const BundleCard = ({ bundle }) => {
	const navigate = useNavigate();

	return (
		<button
			onClick={() => navigate(`/gallery/${bundle.uuid}`)}
			className="group rounded-2xl relative flex flex-col"
		>
			<div className="absolute left-0 bottom-0 z-40 tracking-wide font-bold rounded-bl-2xl mr-10 px-5 py-3 text-white bg-black/70 text-base sm:text-lg md:text-xl lg:text-2xl transition-all duration-300">
				{bundle.displayName.toUpperCase()}
			</div>
			<img
				src={bundle.imageSrc}
				alt={bundle.displayName}
				className="z-30 object-cover w-full h-52 sm:h-60 rounded-2xl shadow-xl"
			/>
		</button>
	);
};

const Bundles = ({ bundles, filter, rarity }) => {
	return (
		<div className="w-full">
			{bundles.isLoading ? (
				<div className="grid gap-10 grid-cols-1 sm:grid-cols-2">
					{[...Array(8).keys()].map((x) => {
						return (
							<div key={x} className="w-full h-52 sm:h-60">
								<Skeleton
									variant="rounded"
									style={{ borderRadius: "16px" }}
									width="100%"
									height="100%"
								/>
							</div>
						);
					})}
				</div>
			) : (
				<div className="w-full grid gap-10 grid-cols-1 sm:grid-cols-2">
					{bundles.data.data.data
						.filter(
							(bundle) =>
								(bundle.contentTier === rarity || rarity === -1) &&
								bundle.displayName
									.toLowerCase()
									.indexOf(filter.toLowerCase()) !== -1
						)
						.map((bundle) => (
							<BundleCard key={bundle.uuid} bundle={bundle} />
						))}
				</div>
			)}
		</div>
	);
};

const SearchBar = ({ filter, setFilter }) => {
	return (
		<div className="flex justify-center">
			<div className="relative w-fit rounded-3xl">
				<input
					value={filter}
					onChange={(e) => setFilter(e.target.value)}
					placeholder="Cari Bundle ..."
					className="placeholder:font-semibold placeholder:text-black/50 w-full sm:w-[400px] h-12 rounded-xl bg-textInput outline outline-2 outline-black/50 px-6 transition-all duration-300"
				/>
				<div className="absolute top-0 bottom-0 right-4 flex items-center">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth={1.5}
						stroke="currentColor"
						className="w-6 h-6"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
						/>
					</svg>
				</div>
			</div>
		</div>
	);
};

const RarityFilter = ({ contentTiers, rarity, setRarity }) => {
	return (
		<div className="max-w-[1000px] overflow-auto">
			{contentTiers.isLoading ? (
				<div className="overflow-auto">
					<div className="w-[900px] flex flex-row gap-x-5">
						{[...Array(6).keys()].map((x) => {
							return (
								<div key={x} className="w-1/6 h-10">
									<Skeleton variant="rounded" width="100%" height="100%" />
								</div>
							);
						})}
					</div>
				</div>
			) : (
				<div className="min-w-full flex overflow-x-auto">
					<div className="min-w-fit flex flex-col pb-2">
						<button
							onClick={() => setRarity(-1)}
							className="min-w-fit flex gap-x-1 items-center px-4 sm:px-6 py-2 outline-none"
						>
							<h1 className="text-sm sm:text-base md:text-lg font-medium sm:font-semibold">
								All
							</h1>
						</button>
						<div
							className={`transition-all duration-300 ${
								rarity === -1 ? "w-full" : "w-0"
							} h-1 ${rarity === -1 && "bg-valored"}`}
						/>
					</div>
					{contentTiers.data.data.data.map((contentTier) => (
						<div
							key={contentTier.uuid}
							className="min-w-fit flex flex-col pb-2"
						>
							<button
								onClick={() => setRarity(contentTier.rank)}
								className="min-w-fit flex gap-x-1 xl:gap-x-3 items-center px-4 sm:px-6 py-2 outline-none"
							>
								<h1 className="text-sm sm:text-base md:text-lg font-medium sm:font-semibold">
									{contentTier.displayName}
								</h1>
								{contentTier.imageSrc && (
									<img
										src={contentTier.imageSrc}
										alt={contentTier.displayName}
										className="w-4 sm:w-6"
									/>
								)}
							</button>
							<div
								className={`transition-all duration-300 ${
									contentTier.rank === rarity ? "w-full" : "w-0"
								} h-1 ${contentTier.rank === rarity && "bg-valored"}`}
							/>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default Gallery;
