import { useNavigate, useParams } from "react-router-dom";

import AccountInformation from "./Information/Account/AccountInformation.js";
import Button from "../Button/RedButton.js";
import OptionalInformation from "./Information/Optional/OptionalInformation.js";
import Popup from "../Popup/Popup.js";
import SecondaryButton from "../Button/SecondaryButton";
import SellerInformation from "./Information/Seller/SellerInformation.js";
import SkinInformation from "./Information/Account/Skins/SkinInformation";
import Summary from "./Summary/Summary.js";
import TertiaryButton from "../Button/TertiaryButton";
import { axios } from "../../constants.js";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { useState } from "react";

export default function ListingForm({ initData, create, update }) {
	const params = useParams();
	const navigate = useNavigate();
	const [name, setName] = useState(initData.name);
	const [email, setEmail] = useState(initData.email);
	const [whatsapp, setWhatsapp] = useState(initData.whatsapp);
	const [lineID, setLineID] = useState(initData.lineID);
	const [bca, setBca] = useState(initData.bca);
	const [lockedAgents, setLockedAgents] = useState(initData.lockedAgents);
	const [rank, setRank] = useState(initData.rank);
	const [changeNicknameAvailable, setChangeNickNameAvailable] = useState(
		initData.changeNicknameAvailable
	);
	const [changeNicknameAvailableIn, setChangeNicknameAvailableIn] = useState(
		initData.changeNicknameAvailableIn
	);
	const [emailVerified, setEmailVerified] = useState(initData.emailVerified);
	const [level, setLevel] = useState(initData.level);
	const [valorantPoints, setValorantPoints] = useState(initData.valorantPoints);
	const [radianitePoints, setRadianitePoints] = useState(
		initData.radianitePoints
	);
	const [additionalDescription, setAdditionalDescription] = useState(
		initData.additionalDescription
	);
	const [ownedSkins, setOwnedSkins] = useState(initData.ownedSkins);
	const [done, setDone] = useState(false);
	const [requirementsFilled, setRequirementsFilled] = useState(true);
	const [screenshot, setScreenshot] = useState(initData.screenshot);
	const [screenshotImageSrc, setScreenshotImageSrc] = useState(
		initData.screenshotImageSrc
	);
	const [discount, setDiscount] = useState(initData.discount);
	const [showChangeDiscountPopup, setShowChangeDiscountPopup] = useState(false);
	const [showDeletePopup, setShowDeletePopup] = useState(false);
	const initialDiscount = parseInt(initData.discount);
	const agentList = useQuery(["agentList"], async () => {
		return await axios.get("get_all_agents/");
	});
	const rankList = useQuery(["rankList"], async () => {
		return await axios.get("get_ranks/");
	});
	const skinList = useQuery(["skinList"], async () => {
		return await axios.get("get_all_skins/");
	});
	const weaponList = useQuery(["weaponList"], async () => {
		return await axios.get("get_weapon_types/");
	});

	const completeAgentList = agentList;
	const completeRankList = rankList;
	const completeWeaponList = weaponList;
	const completeSkinList = skinList;

	const props = {
		params,
		name,
		setName,
		email,
		setEmail,
		whatsapp,
		setWhatsapp,
		lineID,
		setLineID,
		bca,
		setBca,
		lockedAgents,
		setLockedAgents,
		rank,
		setRank,
		changeNicknameAvailable,
		setChangeNickNameAvailable,
		changeNicknameAvailableIn,
		setChangeNicknameAvailableIn,
		emailVerified,
		setEmailVerified,
		level,
		setLevel,
		valorantPoints,
		setValorantPoints,
		radianitePoints,
		setRadianitePoints,
		ownedSkins,
		setOwnedSkins,
		additionalDescription,
		setAdditionalDescription,
		done,
		setDone,
		requirementsFilled,
		setRequirementsFilled,
		screenshot,
		setScreenshot,
		screenshotImageSrc,
		setScreenshotImageSrc,
		discount,
		setDiscount,
		showChangeDiscountPopup,
		setShowChangeDiscountPopup,
		create,
		update,
		initialDiscount,
		completeAgentList,
		completeRankList,
		completeSkinList,
		completeWeaponList,
	};

	const handleDelete = () => {
		axios
			.post(`delete_listing/${params.listing_id}/`)
			.then((resp) => {
				toast.success("Akun Anda telah dihapus");
				setShowDeletePopup(false);
				navigate("/");
			})
			.catch((error) => {
				alert(error);
			});
	};

	return (
		<div>
			<Popup showPopup={showDeletePopup} setShowPopup={setShowDeletePopup}>
				<div className="w-60 h-72 sm:w-96 sm:h-64 flex flex-col gap-y-5 justify-center items-center">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="h-16 w-16 fill-valored"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fillRule="evenodd"
							d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
							clipRule="evenodd"
						/>
					</svg>
					<h1 className="text-center text-base sm:text-lg md:text-xl">
						Akun yang dihapus tidak bisa dikembalikan
					</h1>
					<div className="flex flex-col sm:flex-row gap-y-5 gap-x-5">
						<div className="text-sm sm:text-lg md:text-xl">
							<SecondaryButton
								label="Kembali"
								onClick={() => setShowDeletePopup(false)}
								height={40}
								width={150}
							/>
						</div>
						<div className="text-sm sm:text-lg md:text-xl">
							<Button
								label="Hapus"
								onClick={() => handleDelete()}
								height={40}
								width={150}
							/>
						</div>
					</div>
				</div>
			</Popup>
			{!done && (
				<div className="mx-10 sm:mx-14 md:mx-20 lg:mx-40 2xl:mx-80 flex flex-col gap-y-10 sm:gap-y-20 my-10">
					<div className="flex flex-col gap-y-10">
						{update && (
							<div className="flex justify-end">
								<TertiaryButton
									label="Hapus Akun"
									onClick={() => setShowDeletePopup(true)}
								/>
							</div>
						)}
						<SellerInformation {...props} />
					</div>
					<AccountInformation {...props} />
					<SkinInformation {...props} />
					<OptionalInformation {...props} />
					<div className="flex justify-center sm:justify-end">
						<Button
							width={200}
							height={50}
							disabled={
								name === "" ||
								email === "" ||
								lineID === "" ||
								bca === "" ||
								rank === "" ||
								screenshot === null
							}
							onClick={() => setDone(true)}
							label="Next"
						/>
					</div>
				</div>
			)}
			{done && <Summary {...props} />}
		</div>
	);
}
