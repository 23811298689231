import "./index.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import About from "./routes/About/About";
import App from "./App.js";
import BundleDisplay from "./routes/Gallery/BundleDisplay";
import Capture from "./routes/Capture/Index";
import Checkout from "./routes/Checkout/Checkout";
import Contact from "./routes/Contact/Contact";
import CreateListing from "./routes/CreateListing/CreateListing";
import EditListing from "./routes/EditListing/index.js";
import Error from "./routes/Error/Error";
import Error404 from "./routes/404/index";
import Explore from "./routes/Explore/Explore";
import ExplorePageNumber from "./routes/Explore/ExplorePageNumber";
import FAQs from "./routes/FAQs/FAQs";
import Find from "./routes/Find/Find";
import Gallery from "./routes/Gallery/Gallery";
import GalleryIndex from "./routes/Gallery/Index";
import Giveaway from "./routes/Giveaway/Giveaway";
import Homepage from "./components/Homepage/Homepage";
import Listing from "./routes/Listing/Listing";
import Privacy from "./routes/Privacy/Privacy";
import React from "react";
import ReactDOM from "react-dom";
import Rules from "./routes/Rules/Rules";
import Terms from "./routes/Terms/Terms";

ReactDOM.render(
	<BrowserRouter>
		<React.StrictMode>
			<Routes>
				<Route path="/" element={<App />}>
					<Route index element={<Homepage />}></Route>
					<Route path="mylisting" element={<CreateListing />}></Route>
					<Route path="find" element={<Find />}></Route>
					<Route path="search" element={<Explore />}>
						<Route index element={<ExplorePageNumber />}></Route>
						<Route path=":page_number" element={<ExplorePageNumber />}></Route>
					</Route>
					<Route path="explore" element={<Explore />}>
						<Route index element={<ExplorePageNumber />}></Route>
						<Route path=":page_number" element={<ExplorePageNumber />}></Route>
					</Route>
					<Route path="edit" element={<EditListing />}>
						<Route path=":listing_id" element={<EditListing />}>
							<Route path=":password" element={<EditListing />} />
						</Route>
					</Route>
					<Route path="listing" element={<Listing />}>
						<Route path=":listing_id" element={<Listing />}></Route>
					</Route>
					<Route path="contact" element={<Contact />}></Route>
					<Route path="error" element={<Error />}></Route>
					<Route path="buy">
						<Route path=":listing_id" element={<Checkout />}></Route>
					</Route>
					<Route path="about" element={<About />}></Route>
					<Route path="privacy" element={<Privacy />}></Route>
					<Route path="terms" element={<Terms />}></Route>
					<Route path="rules" element={<Rules />}></Route>
					<Route path="giveaway" element={<Giveaway />}></Route>
					<Route path="faqs" element={<FAQs />}></Route>
					<Route path="gallery" element={<GalleryIndex />}>
						<Route index element={<Gallery />}></Route>
						<Route path=":bundle_uuid" element={<BundleDisplay />}></Route>
					</Route>
					<Route path="capture" element={<Capture />}>
						<Route path=":listing_id" element={<Capture />} />
					</Route>
					<Route path="*" element={<Error404 />}></Route>
				</Route>
			</Routes>
		</React.StrictMode>
	</BrowserRouter>,
	document.getElementById("root")
);
