import LeftArrow from "./LeftArrow";
import RightArrow from "./RightArrow";

function Loading({ trend, listingPerPage }) {
	return (
		<>
			<div className="w-full flex flex-col items-center">
				<div className="flex flex-row items-end gap-x-5 w-5/6 my-10">
					<h1 className="text-xl md:text-4xl self-start font-semibold">
						{trend.displayName} Series
					</h1>
					<button
						disabled={true}
						className="text-md md:text-xl font-bold text-valored hover:text-darkred"
					>
						Lihat Semua
					</button>
				</div>
				<div className="w-5/6 flex flex-col justify-center items-center overflow-hidden h-[400px] rounded-3xl">
					<div className="w-full h-full rounded-3xl bg-slate-400 animate-pulse"></div>
				</div>
				<div className={`flex flex-row justify-center w-5/6 my-10 gap-x-4`}>
					{[...Array(listingPerPage).keys()].map((x, i) => {
						return (
							<div
								key={x}
								className="relative w-full md:w-[45%] xl:w-[30%] h-[350px] drop-shadow-xl my-10"
							>
								{i === 0 && <LeftArrow animate={true} disabled={true} />}
								{i === listingPerPage - 1 && (
									<RightArrow animate={true} disabled={true} />
								)}
								<div className="w-full h-full rounded-xl bg-slate-400 animate-pulse"></div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
}

export default Loading;
