import { Carousel } from "react-responsive-carousel";
import ListingSummary from "../ListingSummary/ListingSummary";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function ListingSlider({ splitListings }) {
	const navigate = useNavigate();
	const [slide, setSlide] = useState(0);

	return (
		<Carousel
			showThumbs={false}
			showStatus={false}
			showArrows={false}
			showIndicators={false}
			swipeScrollTolerance={true}
			swipeable={false}
			selectedItem={slide}
			className="w-full"
		>
			{splitListings.map((listings, i) => {
				return (
					<div
						key={i}
						className="relative my-4 px-6 w-full flex flex-row justify-center gap-x-4"
					>
						{i > 0 && (
							<div
								onClick={() => setSlide(slide - 1)}
								className={`hover:cursor-pointer absolute left-1 top-0 bottom-0 flex items-center`}
							>
								<div className="z-50 w-10 h-10 sm:w-12 sm:h-12 bg-valored rounded-full text-white flex justify-center items-center">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth={3}
										stroke="currentColor"
										className="w-5 h-5 sm:w-6 sm:h-6"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
										/>
									</svg>
								</div>
							</div>
						)}
						{i < splitListings.length - 1 && (
							<div
								onClick={() => setSlide(slide + 1)}
								className={`hover:cursor-pointer absolute right-1 top-0 bottom-0 flex items-center`}
							>
								<div className="z-50 w-10 h-10 sm:w-12 sm:h-12 bg-valored rounded-full text-white flex justify-center items-center">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth={3}
										stroke="currentColor"
										className="w-5 h-5 sm:w-6 sm:h-6"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
										/>
									</svg>
								</div>
							</div>
						)}
						{listings.map((listing) => {
							return (
								<button
									onClick={() => {
										navigate("listing/" + listing.listing_id);
									}}
									key={listing.listing_id}
									className="drop-shadow-lg w-full md:w-1/2 xl:w-1/3 bg-white rounded-xl"
								>
									<ListingSummary key={listing.listing_id} listing={listing} />
								</button>
							);
						})}
					</div>
				);
			})}
		</Carousel>
	);
}

export default ListingSlider;
