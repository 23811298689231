export default function ValorantPoints({ listing }) {
	return (
		<>
			{listing.data.data.radianite_points !== null && (
				<div className="flex w-full items-center">
					<img src="/rp.png" alt="rp" className="w-4 h-4 sm:h-5 sm:w-5" />
					<h1 className="ml-2 font-semibold">
						{listing.data.data.radianite_points}
					</h1>
				</div>
			)}
		</>
	);
}
