function Loading() {
	return (
		<div className="flex justify-center">
			<button className="disabled:opacity-20 w-10 h-10" disabled={true}>
				<div className="flex justify-center items-center">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="h-6 w-6"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						strokeWidth={2}
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M15 19l-7-7 7-7"
						/>
					</svg>
				</div>
			</button>
			{[...Array(8).keys()].map((x) => {
				return (
					<button disabled={true} key={x} className="w-10 h-10 animate-pulse">
						<h1>{x + 1}</h1>
					</button>
				);
			})}
			<button disabled={true} className="disabled:opacity-20 w-10 h-10">
				<div className="flex justify-center items-center">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="h-6 w-6"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						strokeWidth={2}
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M9 5l7 7-7 7"
						/>
					</svg>
				</div>
			</button>
		</div>
	);
}

export default Loading;
