import Button from "../../../Button/RedButton";
import { Deformat } from "../../../PriceInRupiah/Price";
import { axios } from "../../../../constants";

export default function Revalidate({
	name,
	email,
	whatsapp,
	lineID,
	bca,
	lockedAgents,
	rank,
	changeNicknameAvailable,
	changeNicknameAvailableIn,
	emailVerified,
	level,
	valorantPoints,
	radianitePoints,
	ownedSkins,
	additionalDescription,
	screenshot,
	discount,
	setRevalidate,
	params,
	setSubmitLoading,
	setSubmitFinish,
}) {
	const handleSubmit = async () => {
		await axios
			.post("update_listing/" + params.listing_id + "/", {
				name: name,
				email: email,
				whatsapp: whatsapp,
				line: lineID,
				bca: bca,
				rank: rank,
				change_nickname: changeNicknameAvailable,
				change_nickname_available_in: changeNicknameAvailableIn,
				email_verification: emailVerified,
				level: level === "" ? 0 : Deformat(level),
				valorant_points: valorantPoints === "" ? 0 : Deformat(valorantPoints),
				radianite_points:
					radianitePoints === "" ? 0 : Deformat(radianitePoints),
				additional_description: additionalDescription,
				locked_agents: lockedAgents,
				skins: ownedSkins,
				screenshot_id: screenshot,
				discount: parseInt(discount),
			})
			.then((resp) => {
				setSubmitLoading(false);
				setSubmitFinish(true);
			})
			.catch((error) => {
				alert(error);
				setSubmitFinish(false);
				setSubmitLoading(false);
				setRevalidate(false);
			});
	};
	return (
		<div className="flex flex-wrap justify-center items-center h-full w-full">
			<div className="h-full flex flex-col justify-between text-valowhite overflow-auto">
				<div className="flex flex-col">
					<h1 className="text-3xl my-10">Konfirmasi Edit Produk</h1>
					<h1 className="text-xl mb-10">
						Jika Anda meningkatkan diskon, maka produk Anda akan ditambahkan ke
						laman Homepage
					</h1>
					<h1 className="text-xl mb-10">
						Apakah data yang Anda masukkan sudah sesuai?
					</h1>
				</div>
				<div className="flex gap-x-10 justify-center">
					<Button
						label="Ya"
						onClick={() => {
							handleSubmit();
							setRevalidate(false);
							setSubmitLoading(true);
						}}
						width={150}
						height={50}
					/>
					<Button
						label="Tidak"
						onClick={() => setRevalidate(false)}
						width={150}
						height={50}
					/>
				</div>
			</div>
		</div>
	);
}
