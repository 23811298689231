import Button from "../../Button/BlueButton";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../../../hooks/useWindowSize";

export const HOT_BUNDLES = [
	{
		id: 0,
		bundle: "ChronoVoid",
		uuid: "e046854e-406c-37f4-6607-19a9ba8426fc",
		imageSrc: "https://www.valodeals.net/media/bundle-image/ChronoVoid",
	},
	{
		id: 1,
		bundle: "Champions 2022",
		uuid: "f99e5b38-48c7-1146-acfa-9baaf773b844",
		imageSrc: "https://www.valodeals.net/media/bundle-image/Champions_2022",
	},
	{
		id: 2,
		bundle: "Kohaku & Matsuba",
		uuid: "31827d9c-4ccb-8687-a5c1-69991ad1ec98",
		imageSrc: "https://www.valodeals.net/media/bundle-image/Kohaku__Matsuba",
	},
	{
		id: 3,
		bundle: "Reaver 2.0",
		uuid: "f7dcf7e1-485e-0524-ec82-0d97b2c8b40b",
		imageSrc: "https://www.valodeals.net/media/bundle-image/Reaver",
	},
];

export const containerClassName =
	"relative flex items-center w-full h-[200px] sm:h-[300px] lg:h-[400px] xl:h-[500px] overflow-hidden md:rounded-3xl";

const Giveaway = () => {
	const navigate = useNavigate();
	const size = useWindowSize();

	return (
		<div className={containerClassName}>
			<img
				src="/giveaway.png"
				alt="Valorant Background"
				className="h-full md:rounded-3xl object-cover object-top"
			/>
			<div className="absolute z-1 top-0 bottom-0 left-0 right-0 md:rounded-3xl bg-black/30">
				<div className="w-full h-full flex flex-col items-center sm:items-start justify-center gap-y-5 sm:gap-y-16 sm:ml-16">
					<div className="sm:pt-8 sm:pr-10 flex flex-col gap-y-4">
						<h1 className="text-white text-center sm:text-left font-extrabold font-valorant text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl">
							voucher GiveAwAy
						</h1>
						<h1 className="text-white text-center sm:text-left w-full font-semibold leading-tight text-sm md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl drop-shadow-xl">
							Total Voucher Rp600.000 untuk 3 Pemenang
						</h1>
					</div>
					<div className="w-40 text-xs sm:w-48 lg:w-60 md:text-base lg:text-lg">
						<Button
							label="Ikut Sekarang"
							fit={true}
							height={size.width < 768 ? (size.width < 500 ? 35 : 40) : 50}
							onClick={() => navigate("/giveaway")}
							rightIcon={
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-5 w-5 lg:w-6 lg:h-6"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									strokeWidth={2}
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M9 5l7 7-7 7"
									/>
								</svg>
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const Buy = () => {
	const navigate = useNavigate();
	const size = useWindowSize();

	return (
		<div className={containerClassName}>
			<img
				src="/homepage.jpg"
				alt="Valorant Background"
				className="w-full md:rounded-3xl"
			/>
			<div className="absolute z-1 top-0 bottom-0 left-0 right-0 md:rounded-3xl bg-black/40 sm:bg-black/30">
				<div className="w-full h-full flex flex-col items-center sm:items-start justify-center gap-y-5 sm:gap-y-16 sm:ml-16">
					<div className="sm:pt-8 flex flex-col px-16 sm:px-0 gap-y-4">
						<h1 className="text-center sm:text-left drop-shadow-2xl font-extrabold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-zinc-100">
							Marketplace Valorant Pertama di Indonesia
						</h1>
						<h1 className="text-center sm:text-left w-full font-semibold leading-tight text-sm md:text-lg lg:text-xl xl:text-2xl 2xl:text-3xl text-white">
							Mudah, Cepat, Aman
						</h1>
					</div>
					<div className="w-40 text-xs sm:text-sm md:text-base lg:text-lg sm:w-48 lg:w-60">
						<Button
							label="Cari Akun"
							fit={true}
							height={size.width < 768 ? (size.width < 500 ? 35 : 40) : 50}
							onClick={() => navigate("/explore")}
							rightIcon={
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-5 w-5 lg:w-6 lg:h-6"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									strokeWidth={2}
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M9 5l7 7-7 7"
									/>
								</svg>
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const Sell = () => {
	const navigate = useNavigate();
	const size = useWindowSize();

	return (
		<div className={containerClassName}>
			<img
				src="/jettcute.jpg"
				alt="Jett Background"
				className="w-full md:rounded-3xl"
			/>
			<div className="absolute z-1 top-0 bottom-0 left-0 right-0 bg-black/30 md:rounded-3xl">
				<div className="w-full h-full ml-8 sm:ml-16 flex flex-col justify-center gap-y-5 sm:gap-y-16">
					<div className="flex flex-col gap-y-2">
						<h1 className="text-left font-extrabold text-lg md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-white">
							Punya Akun Nganggur?
						</h1>
					</div>
					<div className="w-40 text-xs sm:text-sm md:text-base lg:text-lg sm:w-48 lg:w-60">
						<Button
							label="Jual Sekarang"
							fit={true}
							height={size.width < 768 ? (size.width < 500 ? 35 : 40) : 50}
							onClick={() => navigate("/mylisting")}
							rightIcon={
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="h-5 w-5 lg:w-6 lg:h-6"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									strokeWidth={2}
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M9 5l7 7-7 7"
									/>
								</svg>
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export const Cards = [
	{ id: 0, component: <Giveaway /> },
	{ id: 1, component: <Buy /> },
	{ id: 2, component: <Sell /> },
];
