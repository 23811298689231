function About() {
	return (
		<div className="flex flex-col">
			<div className="relative w-full justify-center h-[600px] overflow-hidden flex flex-col items-center">
				<img
					src="/aboutbg.png"
					alt="dark background"
					className="h-full object-cover"
				/>
				<div className="absolute top-0 bottom-0 left-0 right-0 text-valored text-5xl font-bold flex justify-center items-center">
					About Us
				</div>
				<img
					src="/jettabout.png"
					alt="jett"
					className="absolute bottom-0 left-0 w-1/2 md:w-1/3 lg:w-1/4"
				/>
				<img
					src="/whitevalologo.png"
					alt="valorant logo"
					className="absolute bottom-4 right-4 w-1/4 md:w-1/6 xl:w-1/12"
				/>
			</div>
			<div className="w-full flex flex-col gap-y-16 items-center p-16 py-32 bg-valogray text-white">
				<h1 className="text-2xl text-center" aos-data="fade-down">
					Valodeals memiliki 3 misi utama
				</h1>
				<div className="w-full flex sm:flex-row flex-col gap-y-10 gap-x-10 justify-center">
					<div
						className="w-full sm:w-1/5 flex flex-col gap-y-5"
						data-aos="fade-left"
					>
						<h1 className="text-2xl font-bold text-center">Pertama</h1>
						<h1 className="text-center">
							Mempermudah pencarian dan pembayaran akun yang diinginkan pembeli
						</h1>
					</div>
					<div
						className="w-full sm:w-1/5 flex flex-col gap-y-5"
						data-aos="fade-up"
					>
						<h1 className="text-2xl font-bold text-center">Kedua</h1>
						<h1 className="text-center">
							Mempermudah penjual menginput data akun melalui interface yang
							modern dan mudah digunakan
						</h1>
					</div>
					<div
						className="w-full sm:w-1/5 flex flex-col gap-y-5"
						data-aos="fade-right"
					>
						<h1 className="text-2xl font-bold text-center">Ketiga</h1>
						<h1 className="text-center">
							Memberikan pelayanan yang memuaskan untuk pembeli dan penjual
						</h1>
					</div>
				</div>
			</div>
			<div className="my-20 flex flex-col mx-10 sm:mx-[100px] md:mx-[150px] xl:mx-[300px] font-normal">
				<div className="flex flex-col gap-y-7">
					<h1
						className="text-2xl sm:text-5xl font-medium text-center mb-8"
						data-aos="fade-down"
					>
						About Valodeals
					</h1>
					<div
						data-aos="fade-zoom-in"
						data-aos-offset="200"
						data-aos-easing="ease-in-sine"
						data-aos-duration="600"
						className="flex flex-col gap-y-7"
					>
						<h1 className="text-base sm:text-xl text-center">
							Valodeals adalah website marketplace Valorant pertama di
							Indonesia. Pembeli dan Penjual bisa menjual atau membeli akun
							dengan mudah karena sistem yang sudah dibuat oleh Team Valodeals.
						</h1>
						<h1 className="text-base sm:text-xl text-center">
							Valodeals ditemukan pada April 2022, dan sampai sekarang,
							developer Valodeals melakukan yang terbaik untuk membuat
							Pengalaman Pengguna website kami sebaik mungkin.
						</h1>
						<h1 className="text-base sm:text-xl text-center">
							Valodeals memiliki 3 prinsip utama:
						</h1>
					</div>
				</div>
				<div className="flex flex-col gap-y-16 lg:gap-y-40 my-10 sm:my-20">
					<div className="w-full flex flex-col gap-y-5 sm:gap-y-10 lg:flex-row gap-x-20">
						<img
							src="/sage.jpg"
							alt="easy"
							className="w-full lg:w-1/2 object-cover"
							data-aos="fade-right"
						/>
						<div
							className="flex flex-col gap-y-5 sm:gap-y-12 items-center justify-center w-full lg:w-1/2"
							data-aos="fade-left"
						>
							<h1 className="text-xl sm:text-3xl font-medium text-center">
								Mudah
							</h1>
							<h1 className="text-base sm:text-xl text-center">
								Desain website Valodeals dibuat agar pengguna bisa membeli atau
								menjual akun dengan mudah, dengan menyediakan gambar atau video
								preview skin
							</h1>
						</div>
					</div>
					<div className="w-full flex flex-col gap-y-5 sm:gap-y-10 lg:flex-row gap-x-20">
						<img
							src="/fast.jpg"
							alt="fast"
							className="w-full lg:hidden object-cover"
							data-aos="fade-left"
						/>
						<div
							className="flex flex-col gap-y-5 sm:gap-y-12 items-center justify-center w-full lg:w-1/2"
							data-aos="fade-right"
						>
							<h1 className="text-xl sm:text-3xl font-medium text-center">
								Cepat
							</h1>
							<h1 className="text-base sm:text-xl text-center">
								Pilih akun yang Anda mau dengan cepat menggunakan fitur yang
								disediakan oleh Valodeals
							</h1>
						</div>
						<img
							src="/fast.jpg"
							alt="fast"
							className="hidden w-1/2 lg:block"
							data-aos="fade-left"
						/>
					</div>
					<div className="w-full flex flex-col gap-y-5 sm:gap-y-10 lg:flex-row gap-x-20">
						<img
							src="/cypher.webp"
							alt="secure"
							className="w-full lg:w-1/2 object-cover"
							data-aos="fade-right"
						/>
						<div
							className="flex flex-col gap-y-5 sm:gap-y-12 items-center justify-center w-full lg:w-1/2"
							data-aos="fade-left"
						>
							<h1 className="text-xl sm:text-3xl font-medium text-center">
								Aman
							</h1>
							<h1 className="text-base sm:text-xl text-center">
								Transaksi yang Anda lakukan 100% aman, uang akan ditahan oleh
								Valodeals selama pembeli belum puas dengan transaksi yang
								dilakukan
							</h1>
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-y-8 sm:gap-y-10 my-4 sm:my-16">
					<h1
						className="text-4xl font-bold text-center mb-10"
						data-aos="fade-down"
					>
						Meet the team
					</h1>
					<div
						className="flex flex-col items-center gap-y-10 md:flex-row w-full gap-x-10"
						data-aos="fade-left"
					>
						<img
							src="/andrew.jpg"
							alt="andrew jeremy"
							className="rounded-full w-3/4 md:w-60 md:h-60"
						/>
						<div className="w-3/4 flex flex-col gap-y-2 justify-center">
							<h1 className="text-lg sm:text-xl text-center md:text-left md:text-4xl font-medium">
								Founder and Head Developer of Valodeals
							</h1>
							<h1 className="text-valogray text-center md:text-left text-base sm:text-lg">
								Andrew Jeremy
							</h1>
						</div>
					</div>
					<div
						className="flex flex-col items-center gap-y-10 md:flex-row w-full gap-x-10"
						data-aos="fade-right"
					>
						<img
							src="/parvesh.jpg"
							alt="parvesh mohan"
							className="rounded-full w-2/3 md:hidden"
						/>
						<div className="w-3/4 flex flex-col gap-y-2 justify-center">
							<h1 className="text-lg sm:text-xl text-center md:text-left md:text-4xl font-medium">
								Director of Business
							</h1>
							<h1 className="text-valogray text-center md:text-left text-base sm:text-lg">
								Parvesh Mohan
							</h1>
						</div>
						<img
							src="/parvesh.jpg"
							alt="parvesh mohan"
							className="rounded-full hidden md:block md:w-60 md:h-60"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default About;
