import { useRef, useState } from "react";

import { Carousel } from "react-responsive-carousel";
import RedButton from "../../components/Button/RedButton";
import TextField from "@mui/material/TextField";
import { WINNERS } from "./constants";
import { axios } from "../../constants";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import { useWindowSize } from "../../hooks/useWindowSize";

const TimeCard = ({ time, title }) => {
	return (
		<div className="flex flex-col items-center gap-y-1 sm:gap-y-3 font-poppins">
			<div className="flex gap-x-1.5 text-white text-lg sm:text-3xl md:text-5xl font-bold">
				<div className="bg-valored/70 w-8 h-14 sm:w-10 sm:h-16 md:w-14 md:h-24 rounded-md md:rounded-lg flex justify-center items-center">
					{Math.floor(time / 10)}
				</div>
				<div className="bg-valored/70 w-8 h-14 sm:w-10 sm:h-16 md:w-14 md:h-24 rounded-md md:rounded-lg flex justify-center items-center">
					{time % 10}
				</div>
			</div>
			<div className="text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl font-medium text-gold font-valorant">
				{title}
			</div>
		</div>
	);
};

const Timer = ({ setExpire, expiryTimestamp }) => {
	const { seconds, minutes, hours, days } = useTimer({
		expiryTimestamp,
		onExpire: () => setExpire(true),
	});

	return (
		<div className="flex gap-x-4 sm:gap-x-6 md:gap-x-8 justify-center md:justify-end">
			<TimeCard time={days} title="HARI" />
			<TimeCard time={hours} title="JAM" />
			<TimeCard time={minutes} title="MENIT" />
			<TimeCard time={seconds} title="DETIK" />
		</div>
	);
};

const FormSection = ({ expire }) => {
	const [name, setName] = useState("");
	const [instagram, setInstagram] = useState("");
	const navigate = useNavigate();

	const handleSubmit = () => {
		axios
			.post("create_giveaway_participant/", {
				name: name,
				instagram: instagram,
			})
			.then((resp) => {
				if (resp.data.status === "success") {
					toast.success("Anda telah terdaftar pada giveaway kami!");
					navigate("/");
				} else if (resp.data.status === "already_registered") {
					toast.error("Akun instagram Anda sudah terdaftar pada giveaway kami");
				} else {
					alert("404 error");
				}
			})
			.catch((error) => {
				alert(error);
			});
	};

	return (
		<div className="pb-10 flex justify-center">
			<div className="max-w-[1000px] flex flex-col gap-y-10">
				<div className="text-valored font-bold text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-center">
					Daftarkan diri Anda sekarang!
				</div>
				<div className="w-full flex flex-col sm:flex-row items-center justify-center gap-5">
					<div className="w-[300px]">
						<TextField
							fullWidth
							id="filled-basic"
							name="name"
							label="Nama"
							variant="filled"
							color="error"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
					<div className="w-[300px]">
						<TextField
							fullWidth
							id="filled-basic"
							name="instagram"
							label="Instagram ID"
							variant="filled"
							color="error"
							value={instagram}
							onChange={(e) => setInstagram(e.target.value)}
						/>
					</div>
				</div>
				<div className="flex justify-center">
					<RedButton
						disabled={expire}
						label="Ikut Giveaway"
						height={50}
						width={300}
						rightIcon={
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								strokeWidth={2}
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M9 5l7 7-7 7"
								/>
							</svg>
						}
						onClick={handleSubmit}
					/>
				</div>
			</div>
		</div>
	);
};

function Giveaway() {
	const announcementRef = useRef();
	const [expire, setExpire] = useState(false);
	const expireTime = new Date(2022, 9, 10, 0, 0, 0);
	const windowSize = useWindowSize();

	return (
		<div className="flex flex-col font-montserrat">
			<div className="relative h-[calc(100vh-80px)] w-full">
				<img
					src="/chamber-giveaway.png"
					alt="Giveaway Landing Card"
					className="w-full h-full object-cover object-right lg:object-left"
				/>
				<div className="absolute left-0 md:left-auto right-0 w-full md:w-4/5 lg:w-[55%] xl:w-1/2 top-0 bottom-0 flex flex-col gap-y-20 items-center md:items-start justify-center">
					<div className="font-black text-white flex flex-col gap-y-4 sm:gap-y-8">
						<div className="flex flex-col gap-y-4">
							<div className="flex gap-x-5 justify-center sm:justify-start items-center">
								<h1 className="text-center md:text-left font-valorant font-medium text-5xl sm:text-7xl 2xl:text-8xl">
									giveAwAy
								</h1>
							</div>
							<h1 className="text-center md:text-left text-3xl sm:text-4xl md:text-5xl lg:text-5xl 2xl:text-6xl font-medium font-valorant">
								vOUCHER rP 200.000
							</h1>
						</div>
						<h1 className="text-center md:text-left text-lg lg:text-xl 2xl:text-2xl font-bold text-captureYellow">
							UNTUK 3 PEMENANG
						</h1>
					</div>
					<div className="flex flex-col gap-y-4 items-center md:items-start">
						{!expire && (
							<div className="text-gold font-bold text-center md:text-start text-sm md:text-base xl:text-lg font-poppins">
								Pemenang akan diumumkan dalam
							</div>
						)}
						{expire ? (
							<RedButton
								label="Lihat Pemenang"
								rightIcon={
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth={1.5}
										stroke="currentColor"
										className="w-6 h-6"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M8.25 4.5l7.5 7.5-7.5 7.5"
										/>
									</svg>
								}
								height={50}
								width={windowSize.width < 500 ? 240 : 400}
								onClick={() => {
									if (announcementRef.current) {
										announcementRef.current.scrollIntoView({
											behavior: "smooth",
										});
									}
								}}
							/>
						) : (
							<Timer setExpire={setExpire} expiryTimestamp={expireTime} />
						)}
					</div>
				</div>
			</div>
			<StepSection />
			<FormSection expire={expire} />
			{expire && (
				<div className="pt-10" ref={announcementRef}>
					<AnnouncementSection expire={expire} />
				</div>
			)}
		</div>
	);
}

// const AccountCard = ({ imageSrc }) => {
// 	return (
// 		<div className="relative">
// 			<img src={imageSrc} alt="Account Card" className="w-full rounded-3xl" />
// 		</div>
// 	);
// };

const AnnouncementSection = () => {
	const [slide, setSlide] = useState(0);
	return (
		<div className="flex flex-col gap-y-8 items-center justify-center h-screen relative">
			<img
				src="/announcement.png"
				alt="Announcement"
				className="absolute z-30 w-full h-screen object-cover object-center"
			/>
			<div className="absolute z-30 left-0 right-0 bottom-0 top-0 bg-black/20"></div>
			{slide === 0 && (
				<button
					onClick={() => setSlide(slide + 1)}
					className="absolute right-0 top-0 bottom-0 m-auto z-50 w-12 h-12 sm:w-16 sm:h-16 text-white flex justify-center items-center bg-valored"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth={2}
						stroke="currentColor"
						className="w-8 h-8"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
						/>
					</svg>
				</button>
			)}
			{slide === 1 && (
				<button
					onClick={() => setSlide(slide - 1)}
					className="absolute left-0 top-0 bottom-0 m-auto z-50 w-12 h-12 sm:w-16 sm:h-16 text-white flex justify-center items-center bg-valored"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth={2}
						stroke="currentColor"
						className="w-8 h-8"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
						/>
					</svg>
				</button>
			)}
			<Carousel
				selectedItem={slide}
				showStatus={false}
				showIndicators={false}
				showThumbs={false}
				showArrows={false}
				className="z-40 absolute left-0 right-0 h-screen"
			>
				<div
					data-aos="fade-up"
					className="h-screen flex justify-center items-center text-white drop-shadow-2xl font-bold font-valorant text-5xl sm:text-6xl md:text-7xl lg:text-8xl xl:text-9xl text-center"
				>
					wINNERS
				</div>
				<div className="h-screen flex flex-col gap-y-10 justify-center items-center text-white font-bold text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl text-center">
					{WINNERS.map((winner) => (
						<div key={winner.id} className="font-montserrat drop-shadow-2xl">
							{winner.name}
						</div>
					))}
				</div>
			</Carousel>
		</div>
	);
};

const StepCard = ({ step, imageSrc, description }) => {
	return (
		<div className="flex flex-col gap-y-8 items-center">
			<div className="w-28 h-28 bg-white rounded-full border-valored border-4 flex justify-center items-center">
				<img src={imageSrc} alt="Instagram" className="w-20 h-20" />
			</div>
			<div className="flex flex-col gap-y-3 items-center">
				<div className="text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold text-valored">
					STEP {step}:
				</div>
				<div className="text-center font-bold text-sm lg:text-base xl:text-lg">
					{description}
				</div>
			</div>
		</div>
	);
};

const StepSection = () => {
	return (
		<div className="py-10 pt-20 sm:py-20 flex justify-center px-5">
			<div className="grid grid-rows-3 grid-cols-1 md:grid-rows-1 md:grid-cols-3 max-w-[1000px] gap-5 gap-y-10">
				<StepCard
					step={1}
					imageSrc="/instagram-logo-giveaway.png"
					description={
						<div>
							Like post dan tag 3 temanmu di post giveaway{" "}
							<a
								href="https://www.instagram.com/valodeals/"
								target="_blank"
								rel="noreferrer"
								className="text-valored"
							>
								@valodeals
							</a>
						</div>
					}
				/>
				<StepCard
					step={2}
					imageSrc="/fill-form-giveaway.png"
					description={
						<div>Isi data diri Anda dan daftar ke giveaway kami</div>
					}
				/>
				<StepCard
					step={3}
					imageSrc="/announcement-giveaway.png"
					description={
						<div>
							Pengumuman akan diumumkan di website ini sesuai dengan timer di
							atas
						</div>
					}
				/>
			</div>
		</div>
	);
};

// const AccountCarousel = () => {
// 	return (
// 		<div className="py-20 px-5 flex justify-center">
// 			<div className="normal-rounded-carousel max-w-[1000px]">
// 				<Carousel showStatus={false} showThumbs={false}>
// 					<AccountCard imageSrc="/freejing.png" />
// 					<AccountCard imageSrc="/minos.png" />
// 					<AccountCard imageSrc="/popmii.png" />
// 				</Carousel>
// 			</div>
// 		</div>
// 	);
// };

export default Giveaway;
