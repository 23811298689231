function Loading() {
	return (
		<div className="grid grid-cols-8 xl:grid-cols-12 mx-10 lg:mx-0 gap-x-4 gap-y-4">
			{[...Array(18).keys()].map((x) => {
				return (
					<div
						key={x}
						className="w-full aspect-square bg-slate-400 rounded-full animate-pulse"
					/>
				);
			})}
		</div>
	);
}

export default Loading;
