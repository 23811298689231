import { axiosWithCredentials } from "../../../constants";

function Back({ params, navigate, close }) {
	return (
		<button
			className="absolute top-4 left-4"
			onClick={() => {
				if (close) {
					const handleClose = async () => {
						await axiosWithCredentials
							.post("close_buy_page/" + params.listing_id + "/")
							.then((resp) => {})
							.catch((error) => {
								alert(error);
							});
					};
					handleClose();
				}
				navigate(`/listing/${params.listing_id}/`);
			}}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="h-6 w-6"
				fill="none"
				viewBox="0 0 24 24"
				stroke="black"
				strokeWidth={2}
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M10 19l-7-7m0 0l7-7m-7 7h18"
				/>
			</svg>
		</button>
	);
}

export default Back;
