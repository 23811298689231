import Skeleton from "@mui/material/Skeleton";

export default function SkinNameList({
	filter,
	setFilter,
	ownedSkins,
	setOwnedSkins,
	weaponType,
	completeSkinList,
}) {
	return (
		<div>
			<div className="w-full flex flex-row items-center text-valoblack">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-8 w-8 mx-2"
					viewBox="0 0 20 20"
					fill="currentColor"
				>
					<path
						fillRule="evenodd"
						d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
						clipRule="evenodd"
					/>
				</svg>
				<input
					type="text"
					className="w-full text-valoblack py-3 px-4 bg-inherit"
					onChange={(e) => setFilter(e.target.value)}
					value={filter}
					placeholder="Cari skin yang Anda punya"
				/>
			</div>
			<div className="w-full grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 max-h-[900px] overflow-auto">
				{!completeSkinList.isLoading
					? completeSkinList.data.data.data
							.filter(
								(skin) => skin.weaponType === weaponType || weaponType === "All"
							)
							.map((skin) => {
								if (
									ownedSkins.filter((ownedSkin) => ownedSkin.uuid === skin.uuid)
										.length > 0
								) {
									return null;
								}
								if (
									skin.displayName
										.toLowerCase()
										.indexOf(filter.toLowerCase()) === -1
								) {
									return null;
								}
								return (
									<button
										key={skin.uuid}
										onClick={() => setOwnedSkins([...ownedSkins, skin])}
										className="relative group w-full flex flex-col gap-y-5 justify-center items-center border border-valowhite4 border-collapse p-5 transition-all duration-300 text-valoblack hover:text-white"
									>
										<div className="z-30 absolute right-0 left-0 top-0 bottom-0">
											<div className="transition-all duration-300 group-hover:w-full h-full w-0 bg-redButton"></div>
										</div>
										<img
											className="h-6 sm:h-10 z-40"
											src={skin.imageSrc}
											alt={skin.displayName}
										/>
										<h1 className="text-xs sm:text-sm font-semibold z-40">
											{skin.displayName}
										</h1>
									</button>
								);
							})
					: [...Array(32).keys()].map((x) => (
							<div key={x} className="p-1">
								<Skeleton variant="rectangular" width="100%" height="100px" />
							</div>
					  ))}
			</div>
		</div>
	);
}
