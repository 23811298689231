import ListingsLoading from "./Listings/Loading";
import { trends } from "../constants";

function Loading({ listingPerPage }) {
	return (
		<>
			<div className="w-full flex flex-col divide-y divide-solid divide-black gap-y-10">
				{trends.map((trend) => {
					return (
						<ListingsLoading
							key={trend.uuid}
							listingPerPage={listingPerPage}
							trend={trend}
						/>
					);
				})}
			</div>
		</>
	);
}

export default Loading;
