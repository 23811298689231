import { useNavigate, useSearchParams } from "react-router-dom";

import Button from "../../components/Button/RedButton";

function Error() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	return (
		<div className="my-20 flex items-center justify-center">
			<div className="w-full flex flex-col items-center sm:w-4/5 md:w-2/3 lg:w-1/4">
				<img src="/jettkawai.png" alt="denied" className="w-full rounded-xl" />
				<h1 className="w-full text-center text-2xl font-semibold">
					{searchParams.get("err")
						? searchParams.get("err")
						: "Sepertinya ada masalah dengan perangkat Anda"}
				</h1>
				<div className="mt-5">
					<Button
						label="Home"
						width={300}
						height={50}
						onClick={() => navigate("/")}
					/>
				</div>
			</div>
		</div>
	);
}

export default Error;
