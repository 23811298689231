import Loading from "./Loading";
import { axios } from "../../../../../constants";
import { useQuery } from "react-query";

export default function AgentList({ navigate, listing }) {
	const agentList = useQuery(["agentList"], async () => {
		return await axios.get("get_all_agents/");
	});

	if (agentList.isLoading || listing.isLoading) return <Loading />;
	if (agentList.isError) navigate(`/error?err=${agentList.error.message}`);
	if (listing.isError) navigate(`/error?err=${listing.error.message}`);

	return (
		<div className="grid grid-cols-4 sm:grid-cols-7 gap-4">
			{agentList.data.data.data.map((agent) => {
				if (
					listing.data.data.locked_agents.filter(
						(locked_agent) => locked_agent.uuid === agent.uuid
					).length > 0
				) {
					return (
						<div
							key={agent.uuid}
							className="w-12 sm:w-14 h-12 sm:h-14 md:w-20 md:h-20 relative"
						>
							<img
								src="/red-cross.png"
								alt="red-cross"
								className="absolute w-full h-full"
							/>
							<img
								src={agent.imageSrc}
								alt="AgentImage"
								className="rounded-full object-scale-down"
							/>
						</div>
					);
				} else {
					return (
						<div className="w-full h-full flex items-center justify-center">
							<img
								key={agent.uuid}
								src={agent.imageSrc}
								alt="AgentImage"
								className="rounded-full w-12 sm:w-14 h-12 sm:h-14 md:w-20 md:h-20 object-scale-down"
							/>
						</div>
					);
				}
			})}
		</div>
	);
}
