import Button from "../../../../Button/RedButton";
import { useNavigate } from "react-router-dom";

function Finish({ editPassword }) {
	const navigate = useNavigate();
	return (
		<div className="text-white w-full h-full flex flex-col justify-center items-center">
			<h1 className="text-5xl font-semibold text-left">
				Akun Anda berhasil didaftarkan!
			</h1>
			<h1 className="text-xl text-valogray text-left">
				Akun Anda telah terdaftar dengan password{" "}
				<span className="text-valored font-bold">{editPassword}</span>
			</h1>
			<h1 className="text-xl text-valogray text-left">
				Password juga dikirimkan melalui Email
			</h1>
			<img src="/logo.png" alt="logo" className="w-1/2" />
			<Button
				label="Home"
				onClick={() => {
					navigate("/");
				}}
				width={200}
				height={50}
			/>
		</div>
	);
}

export default Finish;
