import SkinDisplay from "../../SkinDisplay/SkinDisplay";
import { useWindowSize } from "../../../../hooks/useWindowSize";

export default function TheRest({
	navigate,
	show,
	listing,
	setVideoPopup,
	setVideoSrc,
}) {
	const size = useWindowSize();

	if (listing.isLoading) return;
	if (listing.isError) navigate(`/error?err=${listing.error.message}`);

	const theRest = listing.data.data.skins.slice(
		size.width >= 640 ? 3 : 2,
		listing.data.data.skins.length
	);
	return (
		<>
			{show && theRest.length > 0 && (
				<div className="grid grid-cols-2 sm:grid-cols-3 lg:hidden">
					{theRest.map((skin) => {
						return (
							<SkinDisplay
								key={skin.uuid}
								skin={skin}
								setVideoPopup={setVideoPopup}
								setVideoSrc={setVideoSrc}
							/>
						);
					})}
				</div>
			)}
		</>
	);
}
