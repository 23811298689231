export const faqs = {
	title: (
		<div className="py-4 text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold tracking-wide">
			Frequently Asked Questions
		</div>
	),
	rows: [
		{
			title: (
				<div className="text-base sm:text-lg">
					Valodeals tuh website apa sih?
				</div>
			),
			content: (
				<div className="py-4 flex flex-col gap-y-5 text-sm sm:text-base">
					<h1>
						Valodeals merupakan website marketplace Valorant yang didesain untuk
						mempermudah pengguna yang ingin menjual atau membeli akun Valorant.
					</h1>
					<h1>
						Untuk detail lebih lanjut, kamu bisa lihat laman{" "}
						<a
							href="https://www.valodeals.net/about"
							className="text-valored font-bold"
						>
							Tentang Valodeals
						</a>
					</h1>
				</div>
			),
		},
		{
			title: (
				<div className="text-base sm:text-lg">
					Apakah pembelian di Valodeals aman?
				</div>
			),
			content: (
				<div className="py-4 text-sm sm:text-base">
					Dijamin Aman! Pembayaran pembeli tidak langsung diteruskan ke penjual,
					Valodeals akan menahan uang pembeli sampai transaksi selesai.
				</div>
			),
		},
		{
			title: (
				<h1 className="text-base sm:text-lg">
					Kenapa ada tulisan <b>PRODUK SEDANG DIAKSES PEMBELI LAIN</b> pada
					beberapa akun yang dijual?
				</h1>
			),
			content: (
				<h1 className="py-4 text-sm sm:text-base">
					Untuk menjaga kualitas website Valodeals, tentunya 1 akun hanya bisa
					dibeli oleh 1 orang, oleh karena itu, kami membatasi laman pembelian
					agar tidak bisa digunakan oleh lebih dari 1 user dalam satu waktu.
				</h1>
			),
		},
		{
			title: (
				<h1 className="text-base sm:text-lg">
					Apa untungnya ngasih diskon ke akun yang dijual?
				</h1>
			),
			content: (
				<div className="py-4 flex flex-col gap-y-5 text-sm sm:text-base">
					<h1>
						Valodeals akan menampilkan akun yang baru diperbaharui diskonnya
						(ditambahkan diskon atau diberikan diskon) pada halaman utama
						Valodeals.
					</h1>
					<h1>
						Fitur ini ada untuk membantu penjual menyesuaikan harga sesuai
						dengan keinginan masing-masing
					</h1>
				</div>
			),
		},
		{
			title: (
				<h1 className="text-base sm:text-lg">
					Gimana sih cara ngasih diskon ke akun yang dijual?
				</h1>
			),
			content: (
				<h1 className="py-4 text-sm sm:text-base">
					Saat kamu daftarin akun ke Valodeals pertama kali, kami mengirimkan
					email yang menyertakan link untuk edit produk anda. Jadi jangan lupa
					cek emailmu ya!
				</h1>
			),
		},
		{
			title: (
				<h1 className="text-base sm:text-lg">
					Aku udah selesai bayar, sekarang gimana?
				</h1>
			),
			content: (
				<div className="py-4 flex flex-col gap-y-5 text-sm sm:text-base">
					<h1>
						Setelah kamu selesai bayar, admin akan segera konfirmasi
						pembayaranmu. Setelah pembayaran telah dikonfirmasi, kamu akan
						diinvite ke dalam grup line yang berisi kamu, pemilik akun, dan
						admin.
					</h1>
					<h1>
						Grup line ini akan digunakan sebagai sarana pertukaran informasi
						mengenai akun yang anda pilih, jangan lupa untuk ganti informasi
						akun dan menyampaikan keluhan yang kamu hadapi ke admin.
					</h1>
					<h1>
						Grup Line akan ditutup setelah anda puas dengan transaksi akun!
						Selamat menikmati akun barumu :)
					</h1>
				</div>
			),
		},
	],
};
