import SkinInput from "./SkinInput.js";
import SkinList from "./SkinList.js";
import { useState } from "react";

export default function SkinInformation({
	ownedSkins,
	setOwnedSkins,
	completeSkinList,
	completeWeaponList,
}) {
	const [filter, setFilter] = useState("");

	return (
		<div className="text-valoblack flex flex-col">
			<SkinList
				filter={filter}
				setFilter={setFilter}
				ownedSkins={ownedSkins}
				setOwnedSkins={setOwnedSkins}
			/>
			<SkinInput
				filter={filter}
				setFilter={setFilter}
				ownedSkins={ownedSkins}
				setOwnedSkins={setOwnedSkins}
				completeWeaponList={completeWeaponList}
				completeSkinList={completeSkinList}
			/>
		</div>
	);
}
