import All from "./All";
import Available from "./Available";

function VisibilityFilter({ ...props }) {
	return (
		<div className="w-full flex flex-col gap-y-4 p-6">
			<h1 className="text-md font-medium w-full">Ketersediaan</h1>
			<div className="flex flex-col w-full px-2 gap-y-3">
				<All {...props} />
				<Available {...props} />
			</div>
		</div>
	);
}

export default VisibilityFilter;
