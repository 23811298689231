import Faq from "react-faq-component";
import { faqs } from "./constants";

function FAQs() {
	return (
		<>
			<div className="flex flex-col justify-center gap-y-5 mx-10 sm:mx-[100px] md:mx-[200px] lg:mx-[300px] xl:mx-[400px] 2xl:mx-[600px] min-h-[calc(100vh-80px)] py-10 font-normal text-xs leading-6">
				<Faq styles={{ bgColor: "valowhite" }} data={faqs} />
			</div>
		</>
	);
}

export default FAQs;
