import Loading from "./Loading";
import { axios } from "../../../../../constants";
import { useQuery } from "react-query";

export default function SmallAgentList({ navigate, listing }) {
	const agentList = useQuery(["agentList"], async () => {
		return await axios.get("get_all_agents/");
	});

	if (agentList.isLoading || listing.isLoading) return <Loading />;
	if (agentList.isError) navigate(`/error?err=${agentList.error.message}`);
	if (listing.isError) navigate(`/error?err=${listing.error.message}`);

	return (
		<div className="grid grid-cols-8 xl:grid-cols-12 mx-10 lg:mx-0 gap-6">
			{agentList.data.data.data.map((agent) => {
				if (
					listing.data.data.locked_agents.filter(
						(locked_agent) => locked_agent.uuid === agent.uuid
					).length > 0
				) {
					return (
						<div key={agent.uuid} className="w-full h-full relative">
							<img
								src="/red-cross.png"
								alt="red-cross"
								className="absolute w-full h-full"
							/>
							<img
								src={agent.imageSrc}
								alt="AgentImage"
								className="rounded-full"
							/>
						</div>
					);
				} else {
					return (
						<img
							key={agent.uuid}
							src={agent.imageSrc}
							alt="AgentImage"
							className="rounded-full"
						/>
					);
				}
			})}
		</div>
	);
}
