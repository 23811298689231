export default function Screenshot({ screenshotImageSrc }) {
  return (
    <>
      <div className="col-span-2 sm:col-span-1">Screenshot</div>
      <div className="col-span-2 sm:col-span-3">
        <img
          src={screenshotImageSrc}
          alt="screenshot"
          className="w-full sm:w-2/3"
        />
      </div>
    </>
  );
}
