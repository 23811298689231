import SkinDisplay from "../../../components/SkinDisplay";

export default function SkinList({ ownedSkins, setOwnedSkins }) {
	return (
		<div
			className={`grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-0.5 ${
				ownedSkins.length > 0 && "pb-14"
			}`}
		>
			{ownedSkins.map((skin) => (
				<SkinDisplay
					imageSrc={skin.imageSrc}
					name={skin.displayName}
					onClick={() =>
						setOwnedSkins(
							ownedSkins.filter((ownedSkin) => ownedSkin.uuid !== skin.uuid)
						)
					}
					delete_version={true}
				/>
			))}
		</div>
	);
}
