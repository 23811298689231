import MaxPrice from "./MaxPrice";
import MinPrice from "./MinPrice";

export default function PriceFilter({ ...props }) {
	return (
		<div className="w-full flex flex-col gap-y-3 p-6 py-8">
			<div className="w-full flex justify-start">
				<h1 className="text-md font-medium">Harga</h1>
			</div>
			<MinPrice {...props} />
			<MaxPrice {...props} />
		</div>
	);
}
