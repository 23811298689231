import AllSkin from "./AllSkin/AllSkin";
import Loading from "./Loading";
import ShowLess from "./ShowLess/ShowLess";
import ShowMore from "./ShowMore/ShowMore";
import TheRest from "./TheRest/TheRest";
import Top3 from "./Top3/Top3";

export default function SkinList({ ...props }) {
	if (props.listing.isLoading) return <Loading />;
	if (props.listing.isError)
		props.navigate(`/error?err=${props.listing.error.message}`);

	if (props.listing.data.data.skins.length === 0) {
		return (
			<div className="mt-10 flex justify-center w-full">
				<h1 className="text-5xl font-bold">Tidak ada skin</h1>
			</div>
		);
	}

	return (
		<div>
			<Top3 {...props} />
			<ShowMore {...props} />
			<TheRest {...props} />
			<ShowLess {...props} />
			<AllSkin {...props} />
		</div>
	);
}
