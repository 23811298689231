import Button from "../../../components/Button/TertiaryButton";

function NavLink({ params, navigate }) {
	return (
		<div className="hidden lg:flex mb-5 flex-row items-center gap-x-2 text-valored">
			<button
				onClick={() => navigate("/")}
				className="hover:text-black transition-all duration-300"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					fill="currentColor"
					className="w-5 h-5"
				>
					<path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
					<path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
				</svg>
			</button>
			<RightArrow />
			<Button label="Explore" onClick={() => navigate("/explore")} />
			<RightArrow />
			<Button
				label={params.listing_id}
				onClick={() => navigate(`/listing/${params.listing_id}`)}
			/>
		</div>
	);
}

const RightArrow = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			className="h-5 w-5"
			viewBox="0 0 20 20"
			fill="black"
		>
			<path
				fillRule="evenodd"
				d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
				clipRule="evenodd"
			/>
		</svg>
	);
};

export default NavLink;
