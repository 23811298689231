import { useEffect, useState } from "react";

import Listings from "./Listings/Listings";
import Loading from "./Loading";
import ShowMore from "./ShowMore";
import { useWindowSize } from "../../../hooks/useWindowSize";

export default function Bundles({ navigate, bundles }) {
	let size = useWindowSize();

	const [listingPerPage, setListingPerPage] = useState(1);
	const [showSegments, setShowSegments] = useState(1);
	const [shownBundles, setShownBundles] = useState([]);

	useEffect(() => {
		if (size.width < 768) {
			setListingPerPage(1);
		}
		if (size.width >= 768) {
			setListingPerPage(2);
		}
		if (size.width >= 1280) {
			setListingPerPage(3);
		}
		if (bundles.isSuccess) {
			setShownBundles(
				bundles.data.data.data.slice(
					0,
					Math.min(4 * showSegments, bundles.data.data.data.length)
				)
			);
		}
	}, [showSegments, bundles, size]);

	if (bundles.isLoading) return <Loading listingPerPage={listingPerPage} />;
	if (bundles.isError) navigate(`/error?err=${bundles.error.message}`);

	return (
		<div className="sm:mx-10 xl:mx-32">
			<div className="my-5 w-full flex flex-col divide-y-2 divide-solid divide-valogray">
				{shownBundles.map((bundle) => {
					return (
						<Listings
							key={bundle.bundleUuid}
							listings={bundle.listings}
							bundleImageSrc={bundle.bundleImageSrc}
							bundleDisplayName={bundle.bundleDisplayName}
							bundleUuid={bundle.bundleUuid}
							listingPerPage={listingPerPage}
						/>
					);
				})}
				{showSegments * 4 < bundles.data.data.data.length && (
					<ShowMore
						showSegments={showSegments}
						setShowSegments={setShowSegments}
					/>
				)}
			</div>
		</div>
	);
}
