function Loading() {
	return (
		<button
			disabled={true}
			className="relative group w-full h-40 flex justify-center flex-wrap hover:bg-valored border border-valowhite4 border-collapse"
		>
			<div className="w-full h-full bg-slate-400 animate-pulse"></div>
		</button>
	);
}

export default Loading;
