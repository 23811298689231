import AllSkinLoading from "./AllSkin/Loading";
import ShowMore from "./ShowMore/ShowMore";
import Top3Loading from "./Top3/Loading";

function Loading() {
	return (
		<div className="w-full mt-2">
			<Top3Loading />
			<ShowMore disabled={true} />
			<AllSkinLoading />
		</div>
	);
}

export default Loading;
