import ListingForm from "../../components/ListingForm/ListingForm";

function CreateListing() {
	const initData = {
		name: "",
		email: "",
		whatsapp: "",
		lineID: "",
		bca: "",
		lockedAgents: [],
		rank: "",
		changeNicknameAvailable: false,
		changeNicknameAvailableIn: 0,
		emailVerified: false,
		level: null,
		valorantPoints: null,
		radianitePoints: null,
		additionalDescription: "",
		ownedSkins: [],
		screenshot: null,
		screenshotImageSrc: null,
		discount: 0,
	};
	return <ListingForm initData={initData} create={true} />;
}

export default CreateListing;
