import Loading from "./Loading";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useNavigate } from "react-router-dom";

const animatedComponents = makeAnimated();

export default function BundleFilter({
	bundles,
	searchParams,
	setSearchParams,
}) {
	const navigate = useNavigate();
	if (bundles.isLoading) return <Loading />;
	if (bundles.isError) navigate(`/error?err=${bundles.error.message}`);

	const options = bundles.data.data.data.map((bundle) => {
		return {
			value: bundle.uuid,
			label: bundle.displayName,
			imageSrc: bundle.imageSrc,
		};
	});

	return (
		<div className="w-full flex flex-col gap-y-3 p-6">
			<div className="w-full flex justify-start">
				<h1 className="text-md font-medium">Cari bundle di halaman ini</h1>
			</div>
			<div className="w-full flex justify-start">
				<Select
					components={animatedComponents}
					options={options}
					isMulti
					value={options.filter((option) =>
						(searchParams.get("bd") ? searchParams.get("bd") : []).includes(
							option.value
						)
					)}
					onChange={(e) => {
						if (e.length === 0) {
							setSearchParams([...searchParams].filter((f) => f[0] !== "bd"));
						} else {
							setSearchParams({
								...Object.fromEntries([...searchParams]),
								bd: JSON.stringify(e.map((elm) => elm.value)),
							});
						}
					}}
					className="w-full basic-multi-select"
					placeholder="Cari bundle"
					formatOptionLabel={(bundle) => {
						return (
							<>
								{bundle.imageSrc && (
									<div className="flex flex-row items-center text-sm">
										<img
											src={bundle.imageSrc}
											alt="bundleImage"
											className="h-10"
										/>
										<h1 className="ml-2">{bundle.label}</h1>
									</div>
								)}
							</>
						);
					}}
				/>
			</div>
		</div>
	);
}
