import Loading from "./Loading";
import MaxRank from "./MaxRank/MaxRank";
import MinRank from "./MinRank/MinRank";

export default function RankFilter(props) {
	if (props.rankList.isLoading) return <Loading />;
	if (props.rankList.isError)
		props.navigate(`/error?err=${props.rankList.error.message}`);

	return (
		<div className="w-full flex flex-col gap-y-3 p-6">
			<h1 className="text-md font-medium w-full">Rank</h1>
			<MinRank {...props} />
			<MaxRank {...props} />
		</div>
	);
}
