function LeftArrow({ index, setIndex, disabled, animate }) {
	return (
		<div className="z-60 absolute top-0 bottom-0 left-0 flex items-center">
			<button
				disabled={disabled}
				onClick={(e) => {
					setIndex(index - 1);
					e.stopPropagation();
				}}
				className={`bg-valored rounded-full p-2 -ml-6 ${
					animate && "animate-pulse"
				}`}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-7 w-7"
					fill="none"
					viewBox="0 0 24 24"
					stroke="white"
					strokeWidth={2}
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M15 19l-7-7 7-7"
					/>
				</svg>
			</button>
		</div>
	);
}

export default LeftArrow;
