export default function ChangeNickname({ listing }) {
	return (
		<div className="flex items-center gap-x-2 w-full">
			<h1 className="font-semibold">
				{listing.data.data.change_nickname
					? "Change Nickname"
					: "Change Nickname"}
			</h1>
			{listing.data.data.change_nickname ? (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="w-4 h-4 sm:h-6 sm:w-6"
					fill="none"
					viewBox="0 0 24 24"
					stroke="green"
					strokeWidth={2}
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M5 13l4 4L19 7"
					/>
				</svg>
			) : (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="w-4 h-4 sm:h-6 sm:w-6"
					fill="none"
					viewBox="0 0 24 24"
					stroke="red"
					strokeWidth={2}
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M6 18L18 6M6 6l12 12"
					/>
				</svg>
			)}
		</div>
	);
}
