import Button from "../../../Button/RedButton";
import { useNavigate } from "react-router-dom";

function Finish() {
	const navigate = useNavigate();
	return (
		<div className="text-white w-full h-full flex flex-col justify-between items-center">
			<h1 className="text-3xl font-semibold text-center">
				Akun Anda telah diperbaharui!
			</h1>
			<img
				src="/logo.png"
				alt="logo"
				className="w-4/5 sm:w-3/5 md:w-1/2 lg:w-1/3"
			/>
			<Button
				label="Home"
				onClick={() => {
					navigate("/");
				}}
				width={200}
				height={50}
			/>
		</div>
	);
}

export default Finish;
