import SkinDisplayLoading from "../../SkinDisplay/Loading";

function Loading() {
	return (
		<div className="grid-cols-6 hidden lg:grid">
			{[...Array(12).keys()].map((x) => {
				return <SkinDisplayLoading key={x} />;
			})}
		</div>
	);
}

export default Loading;
