import { Deformat, Reformat } from "../../../../components/PriceInRupiah/Price";

export default function MaxPrice({
	maxPricePlaceholder,
	setMaxPricePlaceholder,
}) {
	return (
		<div className="w-full flex justify-start">
			<input
				type="text"
				className="w-full h-10 pl-3 rounded-tl-md rounded-bl-md placeholder:text-valogray"
				placeholder="Harga Maksimum"
				onChange={(e) =>
					setMaxPricePlaceholder(Reformat(Deformat(e.target.value)))
				}
				value={
					maxPricePlaceholder === null
						? ""
						: Reformat(Deformat(maxPricePlaceholder))
				}
			/>
			<div className="w-14 h-10 flex justify-center items-center">
				<h1 className="text-lg font-medium text-valogray w-full h-full flex justify-center items-center bg-white rounded-br-md rounded-tr-md">
					Rp
				</h1>
			</div>
		</div>
	);
}
