import Skeleton from "@mui/material/Skeleton";
import SkinNameList from "./SkinNameList.js";
import { useState } from "react";

export default function SkinInput({
	filter,
	setFilter,
	ownedSkins,
	setOwnedSkins,
	completeWeaponList,
	completeSkinList,
}) {
	const [chosenWeapon, setChosenWeapon] = useState("All");

	const onChosenWeaponTypeChange = (weaponType) => {
		setChosenWeapon(weaponType);
		setFilter("");
	};

	return (
		<div className="h-full flex flex-col rounded-xl border-[2px] border-valogray shadow-lg justify-start sm:justify-center text-valoblack">
			<div className="sm:hidden w-full flex">
				<button
					className={`font-bold min-w-[80px] hover:bg-hoverButton hover:text-valowhite2 py-3 ${
						chosenWeapon === "All" &&
						"rounded-tl-xl text-valowhite2 bg-redButton"
					}`}
					onClick={() => onChosenWeaponTypeChange("All")}
				>
					All
				</button>
				<div className="w-full flex overflow-auto">
					{!completeWeaponList.isLoading
						? completeWeaponList.data.data.data.map((weapon) => {
								return (
									<button
										key={weapon.uuid}
										className={`font-bold hover:bg-hoverButton hover:text-valowhite2 py-5 px-5 ${
											chosenWeapon === weapon.displayName &&
											"text-valowhite2 bg-redButton"
										}`}
										onClick={() => onChosenWeaponTypeChange(weapon.displayName)}
									>
										{weapon.displayName}
									</button>
								);
						  })
						: [...Array(15).keys()].map((x) => {
								return (
									<Skeleton
										key={x}
										variant="rectangular"
										width="100px"
										height="100%"
									/>
								);
						  })}
				</div>
			</div>
			<div className="w-full flex">
				<div className="hidden sm:w-40 sm:flex flex-col">
					<button
						className={`font-bold rounded-tl-xl w-full hover:bg-hoverButton hover:text-valowhite2 py-3 ${
							chosenWeapon === "All" && "text-valowhite2 bg-redButton"
						}`}
						onClick={() => onChosenWeaponTypeChange("All")}
					>
						All
					</button>
					{!completeWeaponList.isLoading
						? completeWeaponList.data.data.data.map((weapon, i) => {
								return (
									<button
										key={weapon.uuid}
										className={`font-bold w-full hover:bg-hoverButton hover:text-valowhite2 py-3 ${
											chosenWeapon === weapon.displayName &&
											"text-valowhite2 bg-redButton"
										} ${
											i === completeWeaponList.length - 1 && "rounded-bl-xl"
										}`}
										onClick={() => onChosenWeaponTypeChange(weapon.displayName)}
									>
										{weapon.displayName}
									</button>
								);
						  })
						: [...Array(19).keys()].map((x) => (
								<div key={x} className="py-0.5">
									<Skeleton variant="rectangular" width="100%" height="40px" />
								</div>
						  ))}
				</div>
				<div className="sm:w-[85%] w-full">
					<SkinNameList
						filter={filter}
						setFilter={setFilter}
						ownedSkins={ownedSkins}
						completeWeaponList={completeWeaponList}
						completeSkinList={completeSkinList}
						setOwnedSkins={setOwnedSkins}
						weaponType={chosenWeapon}
					/>
				</div>
			</div>
		</div>
	);
}
