function All({ visibility, setVisibility }) {
	return (
		<button
			onClick={() => {
				setVisibility("All");
			}}
		>
			<div className="flex flex-row w-full">
				<div
					className={`outline outline-2 outline-valoblack rounded-full h-5 w-5 flex items-center justify-center transition-all duration-300 ${
						visibility === "All" ? "text-green-600" : "text-transparent"
					}`}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="h-4 w-4"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fillRule="evenodd"
							d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
							clipRule="evenodd"
						/>
					</svg>
				</div>
				<div className="ml-5">Semua</div>
			</div>
		</button>
	);
}

export default All;
