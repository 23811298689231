import { Box, Modal } from "@mui/material";
import { useRef, useState } from "react";

import ReactPlayer from "react-player";
import { useWindowSize } from "../../../hooks/useWindowSize";

export default function VideoPopup({
	videoSrc,
	setVideoSrc,
	videoPopup,
	setVideoPopup,
}) {
	const [volume, setVolume] = useState(0);
	const videoRef = useRef();
	const windowSize = useWindowSize();

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		bgcolor: "background.paper",
		boxShadow: "24px",
	};

	return (
		<Modal
			open={videoPopup}
			onClose={() => setVideoPopup(false)}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box width={windowSize.width >= 1024 ? "70%" : "90%"} sx={style}>
				{videoSrc !== null && (
					<div className="relative">
						<ReactPlayer
							pip
							muted={volume === 0}
							loop
							playing
							volume={volume}
							width="100%"
							height="100%"
							ref={videoRef}
							url={videoSrc}
						/>
						<div className="absolute bg-valored/70 bottom-0 right-0 p-3 px-5 rounded-tl-3xl text-yellow-400 flex gap-x-3 sm:gap-x-5">
							<button onClick={() => setVolume(Math.max(volume - 0.05, 0))}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={3}
									stroke="currentColor"
									className="w-4 h-4 sm:w-6 sm:h-6"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M19.5 12h-15"
									/>
								</svg>
							</button>
							<button
								onClick={() => {
									if (volume === 0) {
										setVolume(0.1);
									} else {
										setVolume(0);
									}
								}}
							>
								{volume === 0 ? (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth={2}
										stroke="currentColor"
										className="w-6 h-6 sm:w-8 sm:h-8"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M17.25 9.75L19.5 12m0 0l2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6l4.72-4.72a.75.75 0 011.28.531V19.94a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.506-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.395C2.806 8.757 3.63 8.25 4.51 8.25H6.75z"
										/>
									</svg>
								) : (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth={2}
										stroke="currentColor"
										className="w-6 h-6 sm:w-8 sm:h-8"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"
										/>
									</svg>
								)}
							</button>
							<button onClick={() => setVolume(Math.min(volume + 0.05, 1))}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={2}
									stroke="currentColor"
									className="w-4 h-4 sm:w-6 sm:h-6"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M12 4.5v15m7.5-7.5h-15"
									/>
								</svg>
							</button>
						</div>
					</div>
				)}
				{videoSrc === null && (
					<h1 className="text-xl font-medium text-valowhite">
						No videos are available for this skin
					</h1>
				)}
			</Box>
		</Modal>
	);
}
