import AgentList from "./Agents/AgentList.js";
import RankInput from "./Rank/RankInput.js";
import TwoChoice from "../../../Button/TwoChoice.js";
import { axios } from "../../../../constants.js";
import { useState } from "react";

export default function AccountInformation({
	lockedAgents,
	setLockedAgents,
	rank,
	setRank,
	changeNicknameAvailable,
	setChangeNickNameAvailable,
	changeNicknameAvailableIn,
	setChangeNicknameAvailableIn,
	emailVerified,
	setEmailVerified,
	setScreenshot,
	screenshotImageSrc,
	setScreenshotImageSrc,
	completeAgentList,
	completeRankList,
}) {
	const [showScreenshot, setShowScreenshot] = useState(false);

	return (
		<div className="border-[2px] border-valogray shadow-lg p-5 md:p-10 rounded-xl">
			<div className="w-full flex flex-col justify-center gap-y-16">
				<h1 className="text-xl md:text-3xl text-center font-bold text-blacktext">
					Informasi Akun
				</h1>
				<div className="sm:px-5 md:px-20">
					<AgentList
						lockedAgents={lockedAgents}
						setLockedAgents={setLockedAgents}
						completeAgentList={completeAgentList}
					/>
				</div>
				<div className="sm:px-5 md:px-20">
					<RankInput
						curRank={rank}
						setRank={setRank}
						completeRankList={completeRankList}
					/>
				</div>
				<div className="flex flex-col items-center gap-y-5">
					<h1 className="text-center">Screenshot inventory Anda</h1>
					<div className="flex flex-row gap-x-5 justify-center items-center">
						<input
							type="file"
							className={`w-full sm:w-64 transition-all duration-200 overflow-hidden file:mr-3 file:px-4 file:rounded-full file:py-2 
							file:border-0 file:text-valored file:font-bold file:bg-white/50 hover:file:bg-red-200`}
							name="photo"
							onChange={(e) => {
								const handleChange = async () => {
									let formData = new FormData();
									formData.append("screenshot", e.target.files[0]);
									await axios
										.post("upload_screenshot/", formData, {
											headers: {
												"Content-Type": "multipart/form-data",
											},
										})
										.then((resp) => {
											setScreenshot(resp.data.id);
											setScreenshotImageSrc(resp.data.imageSrc);
										})
										.catch((error) => {
											alert(error);
										});
								};
								handleChange();
							}}
						/>
						{screenshotImageSrc !== null && (
							<button onClick={() => setShowScreenshot(!showScreenshot)}>
								{showScreenshot ? (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-6 w-6"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										strokeWidth={2}
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
										/>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
										/>
									</svg>
								) : (
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-6 w-6"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										strokeWidth={2}
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
										/>
									</svg>
								)}
							</button>
						)}
					</div>
					<img
						src={screenshotImageSrc || "/imagenotfound.png"}
						alt="Screenshot Preview"
						className={`transition-all duration-500 ${
							showScreenshot ? "w-full" : "w-0"
						}`}
					/>
				</div>
				<div className="flex flex-col gap-y-5 items-center">
					<h1>Bisa ubah nickname</h1>
					<TwoChoice
						leftLabel="Ya"
						rightLabel="Tidak"
						left={changeNicknameAvailable}
						setLeft={setChangeNickNameAvailable}
						width={250}
						height={50}
					/>
					{!changeNicknameAvailable && (
						<div className="mx-5 text-sm sm:text-base h-full flex flex-row gap-x-2 items-center">
							<label>Bisa diubah dalam </label>
							<input
								className="text-center md:col-span-3 col-span-2 w-10 outline outline-1 outline-gray-300 rounded-sm placeholder-valoblack"
								type="number"
								onChange={(e) => setChangeNicknameAvailableIn(e.target.value)}
								value={
									changeNicknameAvailableIn === 0
										? ""
										: changeNicknameAvailableIn
								}
								min="1"
								max="30"
							/>
							<label> hari</label>
						</div>
					)}
				</div>
				<div className="flex flex-col gap-y-5 items-center">
					<h1>Email sudah verifikasi</h1>
					<TwoChoice
						leftLabel="Sudah"
						rightLabel="Belum"
						left={emailVerified}
						setLeft={setEmailVerified}
						width={250}
						height={50}
					/>
				</div>
			</div>
		</div>
	);
}
