import Button from "../../../../Button/RedButton";
import { Deformat } from "../../Price/Price";
import TertiaryButton from "../../../../Button/TertiaryButton";
import { axios } from "../../../../../constants";
import { useNavigate } from "react-router-dom";

export default function SellInfo({
	name,
	email,
	whatsapp,
	lineID,
	bca,
	lockedAgents,
	rank,
	changeNicknameAvailable,
	changeNicknameAvailableIn,
	emailVerified,
	level,
	valorantPoints,
	radianitePoints,
	ownedSkins,
	additionalDescription,
	screenshot,
	discount,
	setSellInfoPopup,
	setSubmitLoading,
	setSubmitFinish,
	setEditPassword,
}) {
	const handleSubmit = async () => {
		axios
			.post("create_listing/", {
				name: name,
				email: email,
				whatsapp: whatsapp,
				line: lineID,
				bca: bca,
				rank: rank,
				change_nickname: changeNicknameAvailable,
				change_nickname_available_in: changeNicknameAvailableIn,
				email_verification: emailVerified,
				level: level === "" ? 0 : Deformat(level),
				valorant_points: valorantPoints === "" ? 0 : Deformat(valorantPoints),
				radianite_points:
					radianitePoints === "" ? 0 : Deformat(radianitePoints),
				additional_description: additionalDescription,
				locked_agents: lockedAgents,
				skins: ownedSkins,
				screenshot_id: screenshot,
				discount: discount,
			})
			.then((resp) => {
				setSubmitLoading(false);
				setSubmitFinish(true);
				setEditPassword(resp.data.password);
			})
			.catch((error) => {
				alert(error);
				setSellInfoPopup(false);
				setSubmitFinish(false);
				setSubmitLoading(false);
			});
	};
	const navigate = useNavigate();

	return (
		<div className="flex flex-wrap justify-center items-center w-full">
			<div className="text-xs sm:text-base md:text-lg lg:text-xl h-full flex flex-col gap-y-5 sm:gap-y-10 text-valowhite">
				<h1 className="text-xl sm:text-2xl md:text-3xl xl:text-4xl font-semibold">
					Prosedur Pendaftaran Akun
				</h1>
				<h1>1. Isi semua informasi sesuai dengan akun Anda.</h1>
				<h1>
					2. Valodeals akan mengirimkan email yang berisi link dan password
					untuk merubah informasi akun Anda (menambahkan diskon, hapus akun,
					ganti informasi).
				</h1>
				<h1>
					3. Anda bisa menggunakan password yang diberikan lewat email untuk
					meng-edit produk Anda melalui menu edit produk.
				</h1>
				<h1>
					4. Setiap kali Anda menambahkan diskon produk Anda, produk akan
					ditampilkan di paling atas beranda dan laman explore.
				</h1>
				<div className="flex flex-col gap-y-3 text-xs sm:text-sm">
					<h1 className="text-gray-400">
						Informasi dan harga yang Anda masukkan{" "}
						<span className="text-green-500">bisa</span> diubah dengan
						link/password yang diberikan di email
					</h1>
					<div className="text-gray-400">
						Dengan menekan tombol Daftarkan Akun, Anda telah menyetujui{" "}
						<TertiaryButton
							label="Syarat dan Ketentuan"
							onClick={() => navigate("/terms")}
							lightHover={true}
						/>{" "}
						dan{" "}
						<TertiaryButton
							label="Peraturan"
							onClick={() => navigate("/rules")}
							lightHover={true}
						/>{" "}
						yang berlaku.
					</div>
				</div>
				<div className="flex justify-center">
					<div className="w-4/5 md:w-2/3 lg:w-1/3 xl:w-1/4 text-sm">
						<Button
							label="Daftarkan Akun"
							onClick={() => {
								handleSubmit();
								setSellInfoPopup(false);
								setSubmitLoading(true);
							}}
							fit={true}
							height={50}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
