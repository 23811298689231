function TwoChoice({
	leftLabel,
	rightLabel,
	fit,
	width,
	height,
	left,
	setLeft,
}) {
	return (
		<div
			style={{ width: width, height: height }}
			className={`${
				fit && "w-full"
			} outline-none flex rounded-3xl bg-secondaryButton p-1 text-white`}
		>
			<div className="relative w-full flex rounded-3xl">
				<div className="absolute left-0 right-0 bottom-0 top-0 flex">
					<div
						className={`outline-none transition-all duration-300 ${
							left ? "w-0" : "w-1/2"
						}`}
					/>
					<div className={`outline-none w-1/2 bg-redButton rounded-3xl`} />
				</div>
				<button
					className={`outline-none z-40 w-1/2 transition-all duration-200 rounded-3xl ${
						!left && "text-valoblack"
					} font-bold`}
					onClick={() => setLeft(true)}
				>
					{leftLabel}
				</button>
				<button
					className={`outline-none z-40 w-1/2 transition-all duration-200 rounded-3xl ${
						left && "text-valoblack"
					} font-bold`}
					onClick={() => setLeft(false)}
				>
					{rightLabel}
				</button>
			</div>
		</div>
	);
}

export default TwoChoice;
