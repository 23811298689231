export default function SellerInformationSummary({
	name,
	email,
	whatsapp,
	lineID,
	bca,
}) {
	return (
		<div className="my-10 flex flex-col gap-y-5">
			<h1 className="text-xl md:text-3xl text-left font-bold text-blacktext">
				Informasi Anda
			</h1>
			<div className="flex flex-row gap-x-5">
				<div className="w-1/4 truncate">Nama</div>
				<div className="w-3/4 truncate">{name}</div>
			</div>
			<div className="flex flex-row gap-x-5">
				<div className="w-1/4 truncate">Email</div>
				<div className="w-3/4 truncate">{email}</div>
			</div>
			{whatsapp && (
				<div className="flex flex-row gap-x-5">
					<div className="w-1/4 truncate">No. Whatsapp</div>
					<div className="w-3/4 truncate">{whatsapp}</div>
				</div>
			)}
			<div className="flex flex-row gap-x-5">
				<div className="w-1/4 truncate">No. Rek BCA</div>
				<div className="w-3/4 truncate">{bca}</div>
			</div>
		</div>
	);
}
