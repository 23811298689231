import Loading from "./Loading";
import { Reformat } from "../../../../components/PriceInRupiah/Price";

export default function ListingPrice({ listing, navigate }) {
	if (listing.isLoading) return <Loading />;
	if (listing.isError) navigate(`/error?err=${listing.error.message}`);

	return (
		<div className="flex flex-col justify-start items-start mb-5">
			{listing.data.data.discounted && (
				<div className="font-bold text-valogray text-base xl:text-xl 2xl:text-3xl xl:font-bold mx-4 mt-5 lg:mt-0">
					<s>
						<h1>Rp{Reformat(listing.data.data.total_price)}</h1>
					</s>
				</div>
			)}
			<div
				className={`font-bold text-2xl xl:text-3xl 2xl:text-5xl xl:font-bold mx-4 ${
					!listing.data.data.discounted && "mt-5"
				} lg:mt-0`}
			>
				<h1>Rp{Reformat(listing.data.data.discounted_price)}</h1>
			</div>
		</div>
	);
}
