import Button from "../../Button/RedButton";

function ShowMore({ showSegments, setShowSegments }) {
	return (
		<div className="w-full flex justify-center items-center pt-10 pb-5">
			<div className="w-60">
				<Button
					label="Muat Lebih Banyak"
					onClick={() => setShowSegments(showSegments + 1)}
					height={50}
					fit={true}
				/>
			</div>
		</div>
	);
}

export default ShowMore;
