import { Outlet } from "react-router-dom";

export default function Explore() {
	return (
		<div className="">
			<div className="lg:mx-[100px] xl:mx-[150px] 2xl:mx-[200px]">
				<Outlet />
			</div>
		</div>
	);
}
