function Loading() {
	return (
		<div className="grid grid-cols-4 sm:grid-cols-7 gap-x-4 gap-y-4">
			{[...Array(14).keys()].map((x) => {
				return (
					<div
						key={x}
						className="w-full aspect-square bg-slate-400 rounded-full animate-pulse"
					></div>
				);
			})}
		</div>
	);
}

export default Loading;
