import Image from "./Image";
import Price from "./Price";
import Rank from "./Rank";

export default function Listing({ listing }) {
	const props = {
		listing,
	};
	return (
		<div className="relative h-full flex flex-col items-start w-full">
			{listing.status !== "available" && (
				<div className="absolute w-full h-full rounded-xl bg-black/40 flex justify-center items-center z-50 p-10">
					<h1 className="text-white text-2xl font-semibold">
						{listing.status === "sold" && <>SOLD</>}
						{listing.status === "pending" && <>MENUNGGU KONFIRMASI ADMIN</>}
						{listing.status === "another_user" && <>DIAKSES PEMBELI LAIN</>}
						{listing.status === "taken_down" && <>DIHAPUS</>}
						{listing.status === "unavailable" && <>TIDAK TERSEDIA</>}
					</h1>
				</div>
			)}
			<div className="absolute left-0 top-0 bg-white text-valored rounded-tl-xl px-5 py-1.5 font-bold text-sm sm:text-base">
				{listing.listing_id}
			</div>
			<Image {...props} />
			<Price {...props} />
			<Rank {...props} />
		</div>
	);
}
