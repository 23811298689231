import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Cards, HOT_BUNDLES } from "./constants";

import Button from "../../Button/BlueButton";
import { Carousel } from "react-responsive-carousel";
import { containerClassName } from "./constants";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../../../hooks/useWindowSize";

const BundleCard = ({ imageSrc, uuid, bundle }) => {
	const navigate = useNavigate();

	const size = useWindowSize();

	return (
		<div className={containerClassName}>
			<div className="w-full h-full flex flex-col gap-y-5 items-center">
				<div className="carousel-cover absolute top-0 bottom-0 left-0 right-0 md:rounded-3xl">
					<div className="w-full h-full sm:ml-16 flex flex-col gap-y-5 sm:gap-y-8 md:gap-y-12 lg:gap-y-16 justify-center">
						<div className="carousel-cover-title text-center sm:text-left w-full text-xl sm:text-2xl md:text-3xl lg:text-5xl font-extrabold">
							{bundle} Bundle
						</div>

						<div className="flex justify-center sm:justify-start">
							<div className="text-xs sm:text-sm md:text-base lg:text-lg carousel-cover-button">
								<Button
									inheritText={true}
									label="Lihat Sekarang"
									height={size.width < 768 ? (size.width < 500 ? 35 : 40) : 50}
									fit={true}
									onClick={() => navigate(`/gallery/${uuid}`)}
									rightIcon={
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-5 w-5 lg:w-6 lg:h-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											strokeWidth={2}
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M9 5l7 7-7 7"
											/>
										</svg>
									}
								/>
							</div>
						</div>
					</div>
				</div>

				<img
					src={imageSrc}
					alt={bundle}
					className="h-full object-cover md:rounded-3xl"
				/>

				<div className="absolute top-0 right-0 w-20 sm:w-40 lg:w-60">
					<img src="/new.png" alt="new" className="w-full" />
				</div>
			</div>
		</div>
	);
};

function Index({ bundles, navigate }) {
	return (
		<div>
			<div className="normal-carousel md:hidden w-full flex justify-center md:my-20">
				<Carousel
					showThumbs={false}
					showStatus={false}
					autoPlay={true}
					infiniteLoop={true}
					interval={3000}
					className="w-full md:w-4/5"
				>
					{Cards.map((card) => (
						<div key={card.id}>{card.component}</div>
					))}
					{HOT_BUNDLES.map((bundle) => (
						<BundleCard key={bundle.id} {...bundle} />
					))}
				</Carousel>
			</div>
			<div className="hidden normal-rounded-carousel w-full md:flex justify-center md:my-20">
				<Carousel
					showThumbs={false}
					showStatus={false}
					autoPlay={true}
					infiniteLoop={true}
					interval={3000}
					className="w-full md:w-4/5"
				>
					{Cards.map((card) => (
						<div key={card.id}>{card.component}</div>
					))}
					{HOT_BUNDLES.map((bundle) => (
						<BundleCard key={bundle.id} {...bundle} />
					))}
				</Carousel>
			</div>
		</div>
	);
}

export default Index;
