import AdditionalInformation from "./AdditionalInformation";
import ChangeNickname from "./ChangeNickname";
import ChangeNicknameDate from "./ChangeNicknameDate";
import EmailVerification from "./EmailVerification";
import Level from "./Level";
import Loading from "./Loading";
import RadianitePoints from "./RadianitePoints";
import Rank from "./Rank";
import ValorantPoints from "./ValorantPoints";

export default function AccountDetail({ listing, navigate }) {
	if (listing.isLoading) return <Loading />;
	if (listing.isError) navigate(`/error?err=${listing.error.message}`);

	return (
		<div>
			<div className="flex gap-x-5 w-full bg-valoblack rounded-xl p-4 py-5 text-valowhite">
				<Rank listing={listing} />
				<div className="w-2/3 flex justify-center items-center">
					<div className="flex flex-col gap-y-1 text-xs sm:text-base md:text-lg lg:text-sm xl:text-base 2xl:text-lg">
						<ValorantPoints listing={listing} />
						<RadianitePoints listing={listing} />
						<Level listing={listing} />
						<EmailVerification listing={listing} />
						<ChangeNickname listing={listing} />
						<ChangeNicknameDate listing={listing} />
						<AdditionalInformation listing={listing} />
					</div>
				</div>
			</div>
		</div>
	);
}
