function SkinDisplay({ onClick, imageSrc, name, delete_version }) {
	return (
		<button
			className="text-black relative group w-full border border-valowhite4 border-collapse"
			onClick={() => onClick()}
		>
			<div className="group-hover:text-valored text-transparent transition-all duration-300 absolute top-2 right-2">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					fill="currentColor"
					className="w-6 h-6"
				>
					<path
						fillRule="evenodd"
						d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
						clipRule="evenodd"
					/>
				</svg>
			</div>

			<div className="p-5 h-full w-full rounded-xl flex flex-col gap-y-5 items-center justify-center">
				<img src={imageSrc} alt={name} className="h-10 object-scale-down" />
				<h1 className="text-xs sm:text-sm font-semibold">{name}</h1>
			</div>
		</button>
	);
}

export default SkinDisplay;
