export default function ChangeNicknameDate({ listing }) {
	return (
		<div className="w-full">
			{!listing.data.data.change_nickname && (
				<div className="flex w-full">
					<h1 className="font-semibold">
						(Available: {listing.data.data.change_nickname_available_date})
					</h1>
				</div>
			)}
		</div>
	);
}
