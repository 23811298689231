import { useEffect, useState } from "react";

import Bundles from "./Bundles/Bundles";
import Carousel from "./Carousel/Carousel";
import DiscountListing from "./Bundles/DiscountedListings/DiscountListings";
import { axios } from "../../constants";
import { trends } from "./constants";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

export default function Homepage() {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			setLoading(false);
		}, 1000);
		return () => clearTimeout(timer);
	}, []);

	const bundles = useQuery(["bundles", trends], async () => {
		return await axios.get("get_listings_with_bundles/", {
			params: {
				bundleUuids: JSON.stringify(trends.map((trend) => trend.uuid)),
			},
		});
	});

	const discountedListings = useQuery(["DiscountedListings"], async () => {
		return await axios.get("get_discounted_listings/");
	});

	const navigate = useNavigate();
	const props = {
		discountedListings,
		trends,
		bundles,
		navigate,
	};

	// <div className="relative min-h-full md:min-h-fit">
	// 	<Video />
	// </div>;
	return (
		<>
			{loading && <Loading />}
			<div className="w-full">
				<Carousel {...props} />
				<DiscountListing {...props} />
				<Bundles {...props} />
			</div>
		</>
	);
}

const Loading = () => {
	return (
		<div className="fixed left-0 right-0 top-0 bottom-0 max-h-screen z-100 bg-first flex justify-center items-center">
			<img
				src="/logo.png"
				alt="valodeals logo"
				className="w-4/5 sm:w-[400px] animate-bounce"
			/>
		</div>
	);
};
