import { TextField } from "@mui/material";

export default function OptionalInformation({
	level,
	setLevel,
	valorantPoints,
	setValorantPoints,
	radianitePoints,
	setRadianitePoints,
	additionalDescription,
	setAdditionalDescription,
}) {
	return (
		<div className="border-[2px] border-valogray shadow-lg p-5 md:p-10 rounded-xl">
			<div className="w-full flex flex-col justify-center gap-y-5">
				<h1 className="text-xl md:text-3xl text-center font-bold text-blacktext">
					Opsional
				</h1>
				<div className="flex flex-col sm:flex-row gap-x-10">
					<div className="w-full sm:w-1/4">
						<TextField
							fullWidth
							name="level"
							label="Level"
							variant="filled"
							value={level || ""}
							onChange={(e) => setLevel(e.target.value)}
						/>
					</div>
					<div className="w-full sm:w-2/5">
						<TextField
							fullWidth
							name="Valorant Points"
							label="Valorant Points"
							variant="filled"
							value={valorantPoints || ""}
							onChange={(e) => setValorantPoints(e.target.value)}
						/>
					</div>
					<div className="w-full sm:w-2/5">
						<TextField
							fullWidth
							name="Radianite Points"
							label="Radianite Points"
							variant="filled"
							value={radianitePoints || ""}
							onChange={(e) => setRadianitePoints(e.target.value)}
						/>
					</div>
				</div>
				<div className="w-full">
					<TextField
						fullWidth
						multiline
						minRows={5}
						name="Additional Description"
						label="Deskripsi Tambahan"
						variant="filled"
						value={additionalDescription}
						onChange={(e) => setAdditionalDescription(e.target.value)}
					/>
				</div>
			</div>
		</div>
	);
}
