import toast from "react-hot-toast";

function RedButton({
	label,
	onClick,
	isLoading,
	onSuccess,
	height,
	width,
	fit,
	disabled,
	leftIcon,
	rightIcon,
}) {
	return (
		<button
			onClick={async () => {
				await onClick();
				if (onSuccess) {
					toast.success(onSuccess);
				}
			}}
			style={{
				height: JSON.stringify(height) + "px",
				width: JSON.stringify(width) + "px",
			}}
			disabled={isLoading || disabled}
			className={`disabled:cursor-not-allowed group relative flex flex-row gap-x-5 items-center justify-center rounded-3xl ${
				fit && "w-full h-full"
			}`}
		>
			<div
				className={`${
					isLoading && "bg-valogray"
				} absolute top-0 left-0 right-0 bottom-0 rounded-3xl overflow-hidden flex flex-row`}
			>
				<div
					className={`transition-all duration-500 w-0 group-hover:w-full ${
						isLoading ? "bg-valogray" : "bg-hoverButton"
					}`}
				/>
				<div
					className={`transition-all duration-500 w-full group-hover:w-0 ${
						isLoading ? "bg-valogray" : "bg-redButton"
					}`}
				/>
			</div>
			<div className="w-full flex flex-row gap-x-5 justify-center items-center">
				{leftIcon && <div className="text-white z-40">{leftIcon}</div>}
				<h1 className="font-bold text-white z-40">{label}</h1>
				{rightIcon && <div className="text-white z-40">{rightIcon}</div>}
			</div>
			<div
				className={`${
					!isLoading && "hidden"
				} absolute left-0 right-0 bottom-0 top-0 bg-white/50 rounded-3xl`}
			/>
			<img
				src="/spinner.png"
				alt="loading spinner"
				className={`${!isLoading && "hidden"} absolute animate-spin h-6`}
			/>
		</button>
	);
}

export default RedButton;
