import { Box, Modal, TextField } from "@mui/material";

import RedButton from "../../../components/Button/RedButton";
import SecondaryButton from "../../../components/Button/SecondaryButton";
import { axios } from "../../../constants";
import toast from "react-hot-toast";
import { useState } from "react";
import { useWindowSize } from "../../../hooks/useWindowSize";

function VoucherPopup({ showModal, setShowModal, handleOpenBuyPage }) {
	const [voucher, setVoucher] = useState("");
	const windowSize = useWindowSize();

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		bgcolor: "background.paper",
		borderRadius: "24px",
		boxShadow: 24,
		p: "48px",
	};

	return (
		<Modal
			open={showModal}
			onClose={() => {
				setVoucher("");
				setShowModal(false);
			}}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box width={windowSize.width >= 500 ? "500px" : "320px"} sx={style}>
				<div className="flex flex-col gap-y-4 sm:gap-y-8 justify-between items-center">
					<h1 className="text-3xl font-bold font-valorant text-valored">
						voucher
					</h1>
					<TextField
						fullWidth
						value={voucher}
						onChange={(e) => setVoucher(e.target.value.trim())}
						label="Kode Voucher"
						variant="filled"
						color="error"
					/>
					<div className="flex flex-col sm:flex-row gap-y-2 gap-x-5">
						<RedButton
							onClick={async () => {
								const resp = await axios.get(`validate_voucher/${voucher}`);
								console.log(resp);
								if (resp.data.isValid) {
									handleOpenBuyPage(voucher);
								} else {
									toast.error("Kode Voucher Anda salah");
								}
							}}
							label="Pakai Voucher"
							width={150}
							height={40}
						/>
						<SecondaryButton
							onClick={handleOpenBuyPage}
							label="Tanpa Voucher"
							width={150}
							height={40}
						/>
					</div>
				</div>
			</Box>
		</Modal>
	);
}

export default VoucherPopup;
