import MaxRankLoading from "./MaxRank/Loading";
import MinRankLoading from "./MinRank/Loading";

function Loading() {
	return (
		<div className="w-full flex flex-col gap-y-3 p-6">
			<h1 className="text-md font-medium w-full">Rank</h1>
			<MinRankLoading />
			<MaxRankLoading />
		</div>
	);
}

export default Loading;
