import BundleFilter from "./BundleFilter/BundleFilter";
import CodeFilter from "./CodeFilter/CodeFilter";
import { Deformat } from "../../../components/PriceInRupiah/Price";
import PriceFilter from "./PriceFilter/PriceFilter";
import RankFilter from "./RankFilter/RankFilter";
import RedButton from "../../../components/Button/RedButton";
import SkinFilter from "./SkinFilter/SkinFilter";
import VisibilityFilter from "./VisibilityFilter/VisibilityFilter";
import { useEffect } from "react";

export default function FilterBar({ popup, ...props }) {
	const handleApply = () => {
		let params = { ...Object.fromEntries([...props.searchParams]) };
		if (props.minPricePlaceholder !== null) {
			params = { ...params, min_price: Deformat(props.minPricePlaceholder) };
		}
		if (props.maxPricePlaceholder !== null) {
			params = { ...params, max_price: Deformat(props.maxPricePlaceholder) };
		}
		props.setSearchParams({ ...params });
	};

	useEffect(() => {
		const handler = (e) => {
			if (e.key === "Enter") {
				handleApply();
			}
		};
		document.addEventListener("keydown", handler);
		return () => {
			document.removeEventListener("keydown", handler);
		};
	});

	return (
		<div
			className={`max-h-max w-full divide-y divide-solid divide-valogray ${
				!popup && "shadow-xl"
			} flex flex-col rounded-xl`}
		>
			<h1
				className={`rounded-tl-xl rounded-tr-xl flex justify-center items-center w-full ${
					popup ? "bg-valowhite" : "bg-valowhite"
				} ${popup ? "text-3xl" : "text-lg"} font-medium py-5`}
			>
				Filter
			</h1>
			<CodeFilter {...props} />
			<SkinFilter {...props} />
			<BundleFilter {...props} />
			<PriceFilter {...props} />
			<RankFilter {...props} />
			<VisibilityFilter {...props} />
			<div className="flex justify-center py-5">
				<div className="w-3/5">
					<RedButton
						label="Apply Filters"
						fit={true}
						height={40}
						onClick={() => handleApply()}
					/>
				</div>
			</div>
		</div>
	);
}
