import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import ExploreListings from "./ExploreListings/ExploreListings";
import FilterBar from "./FilterBar/FilterBar";
import FilterList from "./FilterList/FilterList";
import FilterPopup from "./Filter/FilterPopup";
import Pagebar from "./Pagebar/Pagebar";
import Select from "react-select";
import { axios } from "../../constants";
import { useQuery } from "react-query";
import { useState } from "react";

export default function Explore() {
	let params = useParams();
	let navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [codeFilter, setCodeFilter] = useState("");

	const [minPricePlaceholder, setMinPricePlaceholder] = useState(
		searchParams.get("min_price") ? searchParams.get("min_price") : null
	);
	const [maxPricePlaceholder, setMaxPricePlaceholder] = useState(
		searchParams.get("max_price") ? searchParams.get("max_price") : null
	);
	const [minRankPlaceholder, setMinRankPlaceholder] = useState(
		searchParams.get("min_rank") ? searchParams.get("min_rank") : null
	);
	const [maxRankPlaceholder, setMaxRankPlaceholder] = useState(
		searchParams.get("max_rank") ? searchParams.get("max_rank") : null
	);
	const [visibility, setVisibility] = useState("All");

	const listingList = useQuery(
		[
			"listingList",
			searchParams.get("sortby"),
			searchParams.get("q"),
			searchParams.get("bd"),
			searchParams.get("min_price"),
			searchParams.get("max_price"),
			searchParams.get("min_rank"),
			searchParams.get("max_rank"),
			visibility,
			params.page_number,
		],
		async () => {
			return await axios.get(
				"get_listings/" +
					(params.page_number === undefined ? 1 : params.page_number),
				{
					params: {
						sort: searchParams.get("sortby")
							? searchParams.get("sortby")
							: null,
						q: searchParams.get("q") ? searchParams.get("q") : null,
						bd: searchParams.get("bd") ? searchParams.get("bd") : null,
						min_price: searchParams.get("min_price")
							? searchParams.get("min_price")
							: null,
						max_price: searchParams.get("max_price")
							? searchParams.get("max_price")
							: null,
						min_rank: searchParams.get("min_rank")
							? searchParams.get("min_rank")
							: null,
						max_rank: searchParams.get("max_rank")
							? searchParams.get("max_rank")
							: null,
						visibility: visibility,
					},
				}
			);
		}
	);
	const rankList = useQuery("ranks", async () => {
		return await axios.get("get_ranks/");
	});

	const skins = useQuery("skins", async () => {
		return await axios.get("get_all_skins/");
	});

	const bundles = useQuery("bundles", async () => {
		return await axios.get("get_all_bundles/");
	});

	const props = {
		listingList,
		rankList,
		skins,
		bundles,
		params,
		navigate,
		codeFilter,
		setCodeFilter,
		minPricePlaceholder,
		setMinPricePlaceholder,
		maxPricePlaceholder,
		setMaxPricePlaceholder,
		minRankPlaceholder,
		setMinRankPlaceholder,
		maxRankPlaceholder,
		setMaxRankPlaceholder,
		visibility,
		setVisibility,
		searchParams,
		setSearchParams,
	};

	const sort_options = [
		{ value: "newest", label: "Terbaru" },
		{ value: "ascending", label: "Harga Terendah" },
		{ value: "descending", label: "Harga Tertinggi" },
	];

	return (
		<div className="py-10 flex flex-col m-5">
			<div className="flex items-center justify-between sm:justify-end">
				<div className="xl:hidden w-full flex justify-start">
					<FilterPopup {...props} />
				</div>
				<Select
					options={sort_options}
					defaultValue={sort_options[0]}
					value={sort_options.filter(
						(option) => option.value === searchParams.get("sortby")
					)}
					onChange={(e) =>
						setSearchParams({
							...Object.fromEntries([...searchParams]),
							sortby: e.value,
						})
					}
					placeholder={sort_options[0].label}
					className="w-[200px]"
				/>
			</div>
			<div className="flex gap-x-10">
				<div className="hidden xl:flex xl:w-[30%] 2xl:w-1/4 h-fit flex-wrap">
					<FilterBar {...props} popup={false} />
				</div>
				<div className="w-full xl:w-[70%] 2xl:w-3/4">
					<FilterList {...props} />
					<ExploreListings {...props} />
					<Pagebar {...props} />
				</div>
			</div>
		</div>
	);
}
