import Button from "../../../Button/RedButton";

export default function SummaryButtons({
	setDone,
	setSellInfoPopup,
	setSellPopup,
	setRevalidate,
	create,
	update,
}) {
	return (
		<div className="my-10">
			<div className="flex justify-center w-full">
				<div className="flex justify-center gap-x-10 w-[90%]">
					<div className="w-1/2 sm:w-80 mt-5">
						<Button
							label="Edit"
							onClick={() => {
								setDone(false);
								setSellInfoPopup(false);
								setSellPopup(false);
							}}
							fit={true}
							height={50}
						/>
					</div>
					<div className="w-1/2 sm:w-80 mt-5">
						<Button
							label={create ? "Daftarkan Akun" : "Update Produk"}
							onClick={() => {
								if (create) {
									setSellInfoPopup(true);
								}
								if (update) {
									setRevalidate(true);
								}
							}}
							fit={true}
							height={50}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
