export default function FilterList({
	setMinPricePlaceholder,
	setMaxPricePlaceholder,
	setMinRankPlaceholder,
	setMaxRankPlaceholder,
	searchParams,
	setSearchParams,
}) {
	return (
		<div className="flex flex-wrap my-5">
			{[...searchParams].map((filter) => {
				if (filter[0] === "sortby") return null;
				if (filter[0] === "q") return null;
				if (filter[0] === "bd") return null;
				let label = "";
				if (filter[0] === "min_price") {
					label = "Harga Minimum";
				}
				if (filter[0] === "max_price") {
					label = "Harga Maksimum";
				}
				if (filter[0] === "min_rank") {
					label = "Rank Minimum";
				}
				if (filter[0] === "max_rank") {
					label = "Rank Maksimum";
				}
				return (
					<button
						key={filter[0]}
						className="group p-2 px-3 mx-2 rounded-xl outline outline-1 outline-valogray"
						onClick={() => {
							if (filter[0] === "min_price") {
								const new_params = [...searchParams].filter(
									(f) => f[0] !== "min_price"
								);
								setSearchParams(new_params);
								setMinPricePlaceholder(null);
							}
							if (filter[0] === "max_price") {
								const new_params = [...searchParams].filter(
									(f) => f[0] !== "max_price"
								);
								setSearchParams(new_params);
								setMaxPricePlaceholder(null);
							}
							if (filter[0] === "min_rank") {
								const new_params = [...searchParams].filter(
									(f) => f[0] !== "min_rank"
								);
								setSearchParams(new_params);
								setMinRankPlaceholder(null);
							}
							if (filter[0] === "max_rank") {
								const new_params = [...searchParams].filter(
									(f) => f[0] !== "max_rank"
								);
								setSearchParams(new_params);
								setMaxRankPlaceholder(null);
							}
						}}
					>
						<span className="flex justify-center items-center">
							<h1 className="mr-2">{label}</h1>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-5 w-5 group-hover:fill-red-500"
								viewBox="0 0 20 20"
								fill="gray"
							>
								<path
									fillRule="evenodd"
									d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
									clipRule="evenodd"
								/>
							</svg>
						</span>
					</button>
				);
			})}
		</div>
	);
}
