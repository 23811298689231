import SkinDisplayLoading from "../../SkinDisplay/Loading";
import { useWindowSize } from "../../../../hooks/useWindowSize";

function Loading() {
	const size = useWindowSize();
	return (
		<div className="grid grid-cols-2 sm:grid-cols-3 lg:hidden mb-5">
			{[...Array(size.width >= 640 ? 3 : 2).keys()].map((x) => {
				return <SkinDisplayLoading key={x} />;
			})}
		</div>
	);
}

export default Loading;
