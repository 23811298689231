import Price from "./Price";
import { getTotalPrice } from "../../../../constants";

export default function PriceSummary({
	valorantPoints,
	ownedSkins,
	discount,
	initialDiscount,
	showChangeDiscountPopup,
	setShowChangeDiscountPopup,
	setDiscount,
	create,
	update,
}) {
	const totalPrice = getTotalPrice(ownedSkins, valorantPoints);

	return (
		<div className="text-valoblack text-2xl font-bold flex justify-center w-full">
			<Price
				totalPrice={totalPrice}
				discount={discount}
				setDiscount={setDiscount}
				showChangeDiscountPopup={showChangeDiscountPopup}
				setShowChangeDiscountPopup={setShowChangeDiscountPopup}
				initialDiscount={initialDiscount}
				create={create}
				update={update}
			/>
		</div>
	);
}
