export default function ShowLess({ show, setShow, disabled }) {
	return (
		<>
			{show && (
				<div className="lg:hidden my-5">
					<div className="flex items-center justify-center w-full">
						<button disabled={disabled} onClick={() => setShow(false)}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								strokeWidth={2}
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M5 15l7-7 7 7"
								/>
							</svg>
						</button>
					</div>
				</div>
			)}
		</>
	);
}
