function AdditionalInformation({ listing }) {
	return (
		<>
			{listing.data.data.additional_description !== "" && (
				<div className="flex w-full">
					<p className="font-semibold text-left text-valowhite4 whitespace-pre-line">
						{listing.data.data.additional_description}
					</p>
				</div>
			)}
		</>
	);
}

export default AdditionalInformation;
