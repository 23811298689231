import TertiaryButton from "../../components/Button/TertiaryButton";
import TwoChoice from "../../components/Button/TwoChoice";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function Terms() {
	const navigate = useNavigate();
	const [english, setEnglish] = useState(true);

	return (
		<div className="flex flex-col gap-y-5 my-10 mx-10 sm:mx-[100px] md:mx-[200px] lg:mx-[300px] xl:mx-[400px] 2xl:mx-[600px] font-normal">
			<div className="w-full flex justify-center">
				<TwoChoice
					leftLabel="EN"
					rightLabel="ID"
					width={300}
					height={40}
					left={english}
					setLeft={setEnglish}
				/>
			</div>
			<h1 className="text-center font-bold text-3xl mb-5">
				{english ? "Terms and Conditions" : "Syarat dan ketentuan"}
			</h1>
			<p className="text-xl font-bold">
				{english ? "Welcome to Valodeals!" : "Selamat datang di Valodeals!"}
			</p>
			{english ? (
				<>
					<h1>
						These terms and conditions outline the rules and regulations for the
						use of Valodeals's Website, located at{" "}
						<TertiaryButton
							label="www.valodeals.net"
							onClick={() => navigate("/")}
						/>
						.
					</h1>
				</>
			) : (
				<h1>
					Syarat dan ketentuan ini menguraikan aturan dan peraturan untuk
					penggunaan Situs Web Valodeals, yang terletak di{" "}
					<TertiaryButton
						label="www.valodeals.net"
						onClick={() => navigate("/")}
					/>
					.
				</h1>
			)}
			{english ? (
				<h1>
					By accessing this website we assume you accept these terms and
					conditions. Do not continue to use Valodeals if you do not agree to
					take all of the terms and conditions stated on this page.
				</h1>
			) : (
				<h1>
					Dengan mengakses situs ini, Anda menerima syarat dan ketentuan ini.
					Jangan menggunakan Valodeals jika Anda tidak setuju untuk dengan
					syarat dan ketentuan yang tercantum di halaman ini.
				</h1>
			)}
			{english ? (
				<h1>
					The following terminology applies to these Terms and Conditions,
					Privacy Statement and Disclaimer Notice and all Agreements: "Client",
					"You" and "Your" refers to you, "Buyer" refers to the person buying an
					account, "Seller" refers to the person that registered a listing to
					Valodeals and have read the terms and conditions of Valodeals, the
					person log on this website and compliant to the Company’s terms and
					conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers
					to our Company. "Party", "Parties", or "Us", refers to both the Client
					and ourselves. All terms refer to the offer, acceptance and
					consideration of payment necessary to undertake the process of our
					assistance to the Client in the most appropriate manner for the
					express purpose of meeting the Client’s needs in respect of provision
					of the Company’s stated services, in accordance with and subject to,
					prevailing law of Indonesia. Any use of the above terminology or other
					words in the singular, plural, capitalization and/or he/she or they,
					are taken as interchangeable and therefore as referring to same.
				</h1>
			) : (
				<h1>
					Terminologi berikut berlaku untuk Syarat dan Ketentuan ini, Pernyataan
					Privasi dan Pemberitahuan Peringatan dan semua Perjanjian: "Klien" dan
					"Anda" mengacu pada Anda, "Pembeli" mengacu pada pihak klien yang
					membeli akun di website Valodeals, "Penjual" mengacu pada pihak klien
					yang telah menyetujui syarat dan ketentuan dan telah mendaftarkan
					akunnya untuk dijual di website Valodeals, orang yang masuk ke situs
					web ini dan mematuhi syarat dan ketentuan Perusahaan. "Perusahaan",
					"Kami Sendiri", dan "Kami"mengacu pada Perusahaan kami. "Pihak", "Para
					Pihak", atau "Kami", mengacu pada Klien dan kami sendiri. Semua
					istilah mengacu pada penawaran, penerimaan, dan pertimbangan
					pembayaran yang diperlukan untuk melakukan proses bantuan kami kepada
					Klien dengan cara yang paling tepat untuk tujuan yang jelas dalam
					memenuhi kebutuhan Klien sehubungan dengan penyediaan layanan yang
					dinyatakan Perusahaan, sesuai dengan dan mengikuti hukum yang berlaku
					di Indonesia. Setiap penggunaan terminologi di atas atau kata-kata
					lain dalam bentuk tunggal, jamak, huruf besar dan/atau dia, dianggap
					dapat dipertukarkan dan oleh karena itu mengacu pada yang hal yang
					sama.
				</h1>
			)}

			<h3>
				<strong>{english ? "Trade Rules" : "Peraturan Transaksi"}</strong>
			</h3>
			<div>
				{english ? (
					<div>
						You agree to abide by{" "}
						<TertiaryButton
							label="Valodeals' trade rules"
							onClick={() => navigate("/rules")}
						/>
					</div>
				) : (
					<div>
						Anda setuju untuk mengikuti{" "}
						<TertiaryButton
							label="Peraturan Transaksi Valodeals"
							onClick={() => navigate("/rules")}
						/>
					</div>
				)}
			</div>

			<h3>
				<strong>Cookies</strong>
			</h3>
			{english ? (
				<p>
					We employ the use of cookies. By accessing Valodeals, you agreed to
					use cookies in agreement with the Valodeals's Privacy Policy.{" "}
				</p>
			) : (
				<p>
					Kami menerapkan penggunaan cookie. Dengan mengakses Valodeals, Anda
					setuju untuk menggunakan cookie sesuai dengan Kebijakan Privasi
					Valodeals.
				</p>
			)}
			{english ? (
				<p>
					Most interactive websites use cookies to let us retrieve the user’s
					details for each visit. Cookies are used by our website to enable the
					functionality of certain areas to make it easier for people visiting
					our website. Some of our affiliate/advertising partners may also use
					cookies.
				</p>
			) : (
				<p>
					Sebagian besar situs web interaktif menggunakan cookie untuk
					memungkinkan kami mengambil detail pengguna untuk setiap kunjungan.
					Cookie digunakan oleh situs web kami untuk mengaktifkan fungsionalitas
					area tertentu untuk memudahkan orang mengunjungi situs web kami.
					Beberapa mitra afiliasi/iklan kami juga dapat menggunakan cookie.
				</p>
			)}
			<h3>{english ? <strong>License</strong> : <strong>Lisensi</strong>}</h3>
			{english ? (
				<p>
					Unless otherwise stated, Valodeals and/or its licensors own the
					intellectual property rights for all material on Valodeals. All
					intellectual property rights are reserved. You may access this from
					Valodeals for your own personal use subjected to restrictions set in
					these terms and conditions.
				</p>
			) : (
				<p>
					Kecuali dinyatakan sebaliknya, Valodeals dan/atau pemberi lisensinya
					memiliki hak kekayaan intelektual untuk semua materi di Valodeals.
					Semua hak kekayaan intelektual dilindungi. Anda dapat mengakses ini
					dari Valodeals untuk penggunaan pribadi Anda dengan batasan yang
					diatur dalam syarat dan ketentuan ini.
				</p>
			)}
			{english ? <p>You must not:</p> : <p>Anda tidak diperbolehkan:</p>}
			{english ? (
				<div className="flex flex-col">
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>Republish material from Valodeals</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>Sell, rent or sub-license material from Valodeals</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>Reproduce, duplicate or copy material from Valodeals</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>Redistribute content from Valodeals</h1>
					</div>
				</div>
			) : (
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>Publikasikan ulang materi dari Valodeals</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							Menjual, menyewakan, atau mensublisensikan materi dari Valodeals
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							Mereproduksi, menggandakan, atau menyalin materi dari Valodeals
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>Distribusi ulang konten dari Valodeals</h1>
					</div>
				</div>
			)}
			{english ? (
				<p>
					This Agreement shall begin on the date hereof. Our Terms and
					Conditions were created with the help of the{" "}
					<a href="https://www.termsfeed.com/terms-conditions-generator/">
						TermsFeed Free Terms and Conditions Generator
					</a>
					.
				</p>
			) : (
				<p>
					Perjanjian ini akan dimulai pada tanggal Perjanjian ini. Syarat dan
					Ketentuan kami dibuat dengan bantuan{" "}
					<a href="https://www.termsfeed.com/terms-conditions-generator/">
						TermsFeed Free Terms and Conditions Generator
					</a>
				</p>
			)}
			{english ? (
				<p>
					Parts of this website offer an opportunity for users to post and
					exchange opinions and information in certain areas of the website.
					Valodeals does not filter, edit, publish or review Comments prior to
					their presence on the website. Comments do not reflect the views and
					opinions of Valodeals,its agents and/or affiliates. Comments reflect
					the views and opinions of the person who post their views and
					opinions. To the extent permitted by applicable laws, Valodeals shall
					not be liable for the Comments or for any liability, damages or
					expenses caused and/or suffered as a result of any use of and/or
					posting of and/or appearance of the Comments on this website.
				</p>
			) : (
				<p>
					Beberapa bagian dari situs web ini menawarkan kesempatan bagi pengguna
					untuk memposting dan menyatakan pendapat dan informasi di area
					tertentu dari website ini. Valodeals tidak memfilter, mengedit,
					menerbitkan, atau meninjau Komentar sebelum kehadirannya di situs web.
					Komentar tidak mencerminkan pandangan dan opini Valodeals, agen
					dan/atau afiliasinya. Komentar mencerminkan pandangan dan pendapat
					orang yang memposting pandangan dan pendapat mereka. Selama diizinkan
					oleh undang-undang yang berlaku, Valodeals tidak bertanggung jawab
					atas Komentar atau atas kewajiban, kerusakan, atau pengeluaran apa pun
					yang disebabkan dan/atau dihasilkan sebagai akibat dari penggunaan
					dan/atau postingan dan/atau tampilan Komentar pada website ini.
				</p>
			)}
			{english ? (
				<p>
					Valodeals reserves the right to monitor all Comments and to remove any
					Comments which can be considered inappropriate, offensive or causes
					breach of these Terms and Conditions.
				</p>
			) : (
				<p>
					Valodeals berhak untuk memantau semua Komentar dan menghapus Komentar
					apa pun yang dapat dianggap tidak pantas, menyinggung, atau
					menyebabkan pelanggaran terhadap Syarat dan Ketentuan ini.
				</p>
			)}
			{english ? (
				<p>You warrant and represent that:</p>
			) : (
				<p>Anda menjamin dan menyatakan bahwa:</p>
			)}
			{english ? (
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							You are entitled to post the Comments on our website and have all
							necessary licenses and consents to do so;
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							The Comments do not invade any intellectual property right,
							including without limitation copyright, patent or trademark of any
							third party;
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							The Comments do not contain any defamatory, libelous, offensive,
							indecent or otherwise unlawful material which is an invasion of
							privacy
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							The Comments will not be used to solicit or promote business or
							custom or present commercial activities or unlawful activity.
						</h1>
					</div>
				</div>
			) : (
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							Anda berhak memposting Komentar di website kami dan memiliki semua
							lisensi dan persetujuan yang diperlukan untuk melakukannya;
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							Komentar tidak menyerang hak kekayaan intelektual apa pun,
							termasuk namun tidak terbatas pada hak cipta, paten, atau merek
							dagang dari pihak ketiga mana pun;
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							Komentar tidak mengandung materi yang memfitnah, menyinggung,
							tidak senonoh atau melanggar hukum yang merupakan pelanggaran
							privasi
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							Komentar tidak akan digunakan untuk meminta atau mempromosikan
							bisnis atau kebiasaan atau menyajikan aktivitas komersial atau
							aktivitas yang melanggar hukum.
						</h1>
					</div>
				</div>
			)}
			{english ? (
				<p>
					You hereby grant Valodeals a non-exclusive license to use, reproduce,
					edit and authorize others to use, reproduce and edit any of your
					Comments in any and all forms, formats or media.
				</p>
			) : (
				<p>
					Dengan ini, Anda memberikan Valodeals lisensi non-eksklusif untuk
					menggunakan, mereproduksi, mengedit, dan mengizinkan orang lain untuk
					menggunakan, mereproduksi, dan mengedit Komentar Anda dalam segala
					bentuk, format, atau media.
				</p>
			)}
			<h3>
				{english ? (
					<strong>Hyperlinking to our Content</strong>
				) : (
					<strong>Hyperlink ke Konten kami</strong>
				)}
			</h3>
			{english ? (
				<p>
					The following organizations may link to our Website without prior
					written approval:
				</p>
			) : (
				<p>
					Organisasi berikut dapat menautkan ke Situs Web kami tanpa persetujuan
					tertulis sebelumnya:
				</p>
			)}
			{english ? (
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>Government agencies;</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>Search engines;</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>News organizations;</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							Online directory distributors may link to our Website in the same
							manner as they hyperlink to the Websites of other listed
							businesses; and
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							System wide Accredited Businesses except soliciting non-profit
							organizations, charity shopping malls, and charity fundraising
							groups which may not hyperlink to our Web site.
						</h1>
					</div>
				</div>
			) : (
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>Agensi pemerintahan;</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							<i>Search Engine</i>
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>Organisasi berita;</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							Distributor direktori online dapat menautkan ke Situs Web kami
							dengan cara yang sama seperti mereka menautkan ke Situs Web bisnis
							lain yang terdaftar;
						</h1>
					</div>
				</div>
			)}
			{english ? (
				<p>
					These organizations may link to our home page, to publications or to
					other Website information so long as the link:
				</p>
			) : (
				<p>
					Organisasi-organisasi ini dapat menautkan ke beranda kami, ke
					publikasi, atau ke informasi Situs Web lainnya selama tautan tersebut:
				</p>
			)}
			{english ? (
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row gap-x-3">
						<div>(a)</div>
						<h1>is not in any way deceptive; </h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>(b)</div>
						<h1>
							does not falsely imply sponsorship, endorsement or approval of the
							linking party and its products and/or services; and
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>(c)</div>
						<h1>fits within the context of the linking party’s site.</h1>
					</div>
				</div>
			) : (
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row gap-x-3">
						<div>(a)</div>
						<h1>sama sekali tidak menipu; </h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>(b)</div>
						<h1>
							tidak secara keliru menyiratkan sponsor, dukungan atau persetujuan
							dari pihak yang menghubungkan dan produk dan/atau layanannya; dan
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>(c)</div>
						<h1>sesuai dengan konteks situs pihak yang menautkan.</h1>
					</div>
				</div>
			)}
			{english ? (
				<p>
					We may consider and approve other link requests from the following
					types of organizations:
				</p>
			) : (
				<p>
					Kami dapat mempertimbangkan dan menyetujui permintaan tautan lain dari
					jenis organisasi berikut:
				</p>
			)}
			{english ? (
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							commonly-known consumer and/or business information sources;
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>dot.com community sites;</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>associations or other groups representing charities;</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>online directory distributors;</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>internet portals;</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>accounting, law and consulting firms; and</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>educational institutions and trade associations.</h1>
					</div>
				</div>
			) : (
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							sumber informasi konsumen dan/atau bisnis yang umum diketahui;
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>situs komunitas dot.com;</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>asosiasi atau kelompok lain yang mewakili badan amal;</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>distributor direktori online;</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>portal internet;</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>firma akuntansi, hukum dan konsultan; dan</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>lembaga pendidikan dan asosiasi perdagangan.</h1>
					</div>
				</div>
			)}
			{english ? (
				<p>
					We will approve link requests from these organizations if we decide
					that:
				</p>
			) : (
				<p>
					Kami akan menyetujui permintaan tautan dari organisasi-organisasi ini
					jika kami memutuskan bahwa:
				</p>
			)}
			{english ? (
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row gap-x-3">
						<div>(a)</div>
						<h1>
							the link would not make us look unfavorably to ourselves or to our
							accredited businesses;
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>(b)</div>
						<h1>
							the organization does not have any negative records with us;
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>(c)</div>
						<h1>
							the benefit to us from the visibility of the hyperlink compensates
							the absence of Valodeals; and
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>(d)</div>
						<h1>the link is in the context of general resource information.</h1>
					</div>
				</div>
			) : (
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row gap-x-3">
						<div>(a)</div>
						<h1>
							tautan tersebut tidak akan membuat kami terlihat buruk bagi diri
							kami sendiri atau bisnis terakreditasi kami;
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>(b)</div>
						<h1>organisasi tidak memiliki rekor negatif apapun dengan kami;</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>(c)</div>
						<h1>
							manfaat bagi kami dari visibilitas hyperlink mengkompensasi
							ketiadaan Valodeals; dan
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>(d)</div>
						<h1>tautannya berada dalam konteks informasi sumber daya umum.</h1>
					</div>
				</div>
			)}

			{english ? (
				<p>
					These organizations may link to our home page so long as the link:
				</p>
			) : (
				<p>
					Organisasi-organisasi ini dapat menautkan ke beranda kami selama
					tautan tersebut:
				</p>
			)}

			{english ? (
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row gap-x-3">
						<div>(a)</div>
						<h1>is not in any way deceptive;</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>(b)</div>
						<h1>
							does not falsely imply sponsorship, endorsement or approval of the
							linking party and its products or services; and
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>(c)</div>
						<h1>fits within the context of the linking party’s site.</h1>
					</div>
				</div>
			) : (
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row gap-x-3">
						<div>(a)</div>
						<h1>sama sekali tidak menipu;</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>(b)</div>
						<h1>
							tidak secara keliru menyiratkan sponsorship, dukungan atau
							persetujuan dari pihak yang menghubungkan dan produk atau
							layanannya; dan
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>(c)</div>
						<h1>sesuai dengan konteks situs pihak yang menautkan.</h1>
					</div>
				</div>
			)}

			{english ? (
				<p>
					If you are one of the organizations listed in paragraph 2 above and
					are interested in linking to our website, you must inform us by
					sending an e-mail to Valodeals. Please include your name, your
					organization name, contact information as well as the URL of your
					site, a list of any URLs from which you intend to link to our Website,
					and a list of the URLs on our site to which you would like to link.
					Wait 2-3 weeks for a response.
				</p>
			) : (
				<p>
					Jika Anda adalah salah satu organisasi yang tercantum dalam paragraf 2
					di atas dan tertarik untuk menautkan ke situs web kami, Anda harus
					memberi tahu kami dengan mengirimkan email ke Valodeals. Harap
					sertakan nama Anda, nama organisasi Anda, informasi kontak serta URL
					situs Anda, daftar URL apa pun yang ingin Anda tautkan ke Situs Web
					kami, dan daftar URL di situs kami yang ingin Anda tuju tautan. Tunggu
					2-3 minggu untuk tanggapan.
				</p>
			)}

			{english ? (
				<p>Approved organizations may hyperlink to our Website as follows:</p>
			) : (
				<p>
					Organisasi yang disetujui dapat membuat hyperlink ke Situs Web kami
					sebagai berikut:
				</p>
			)}

			{english ? (
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>By use of our corporate name; or</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>By use of the uniform resource locator being linked to; or</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							By use of any other description of our Website being linked to
							that makes sense within the context and format of content on the
							linking party’s site.
						</h1>
					</div>
				</div>
			) : (
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>Dengan menggunakan nama perusahaan kami; atau</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							Dengan menggunakan pencari sumber daya seragam yang menautkan ke;
							atau
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							Dengan menggunakan deskripsi lain dari Website kami yang
							menautkan, masuk akal dalam konteks dan format konten di situs
							pihak yang menautkan.
						</h1>
					</div>
				</div>
			)}

			{english ? (
				<p>
					No use of Valodeals's logo or other artwork will be allowed for
					linking absent a trademark license agreement.
				</p>
			) : (
				<p>
					Tidak ada penggunaan logo Valodeals atau karya seni lainnya yang
					diizinkan untuk mentautkan jika tidak ada perjanjian lisensi merek
					dagang.
				</p>
			)}

			<h3>{english ? <strong>iFrames</strong> : <strong>iFrame</strong>}</h3>

			{english ? (
				<p>
					Without prior approval and written permission, you may not create
					frames around our Webpages that alter in any way the visual
					presentation or appearance of our Website.
				</p>
			) : (
				<p>
					Tanpa persetujuan dan izin tertulis sebelumnya, Anda tidak boleh
					membuat bingkai di sekitar Halaman Web kami yang mengubah presentasi
					visual atau tampilan Situs Web kami dengan cara apa pun.
				</p>
			)}

			<h3>
				{english ? (
					<strong>Content Liability</strong>
				) : (
					<strong>Tanggung Jawab Konten</strong>
				)}
			</h3>

			{english ? (
				<p>
					We shall not be hold responsible for any content that appears on your
					Website. You agree to protect and defend us against all claims that is
					rising on your Website. No link(s) should appear on any Website that
					may be interpreted as libelous, obscene or criminal, or which
					infringes, otherwise violates, or advocates the infringement or other
					violation of, any third party rights.
				</p>
			) : (
				<p>
					Kami tidak akan bertanggung jawab atas konten apa pun yang muncul di
					Website Anda. Anda setuju untuk melindungi dan membela kami dari semua
					klaim yang muncul di Situs Web Anda. Tidak ada tautan yang muncul di
					Situs Web mana pun yang dapat ditafsirkan sebagai fitnah, cabul, atau
					kriminal, atau yang melanggar, jika tidak, melanggar, atau mendukung
					pelanggaran atau pelanggaran lain terhadap, hak pihak ketiga mana pun.
				</p>
			)}

			<h3>
				{english ? (
					<strong>Your Privacy</strong>
				) : (
					<strong>Privasi Anda</strong>
				)}
			</h3>

			<div className="flex flex-row gap-x-1.5">
				{english ? <h1>Please read </h1> : <h1>Silahkan baca </h1>}
				<TertiaryButton
					label={english ? "Privacy Policy" : "Kebijakan Privasi"}
					onClick={() => navigate("/privacy")}
				/>
			</div>

			<h3>
				{english ? (
					<strong>Reservation of Rights</strong>
				) : (
					<strong>Reservasi Hak</strong>
				)}
			</h3>

			{english ? (
				<p>
					We reserve the right to request that you remove all links or any
					particular link to our Website. You approve to immediately remove all
					links to our Website upon request. We also reserve the right to amen
					these terms and conditions and it’s linking policy at any time. By
					continuously linking to our Website, you agree to be bound to and
					follow these linking terms and conditions.
				</p>
			) : (
				<p>
					Kami berhak untuk meminta Anda menghapus semua tautan atau tautan
					tertentu ke Website kami. Anda menyetujui untuk segera menghapus semua
					tautan ke Situs Web kami berdasarkan permintaan. Kami juga berhak
					untuk mengubah syarat dan ketentuan ini dan kebijakan tautannya kapan
					saja. Dengan terus menautkan ke Situs Web kami, Anda setuju untuk
					terikat dan mengikuti syarat dan ketentuan penautan ini.
				</p>
			)}

			<h3>
				{english ? (
					<strong>Removal of links from our website</strong>
				) : (
					<strong>Penghapusan tautan dari situs web kami</strong>
				)}
			</h3>

			{english ? (
				<p>
					If you find any link on our Website that is offensive for any reason,
					you are free to contact and inform us any moment. We will consider
					requests to remove links but we are not obligated to or so or to
					respond to you directly.
				</p>
			) : (
				<p>
					Jika Anda menemukan tautan apa pun di Website kami yang menyinggung
					karena alasan apa pun, Anda bebas untuk menghubungi dan memberi tahu
					kami kapan saja. Kami akan mempertimbangkan permintaan untuk menghapus
					tautan tetapi kami tidak berkewajiban untuk menanggapi Anda secara
					langsung.
				</p>
			)}

			{english ? (
				<p>
					We do not ensure that the information on this website is correct, we
					do not warrant its completeness or accuracy; nor do we promise to
					ensure that the website remains available or that the material on the
					website is kept up to date.
				</p>
			) : (
				<p>
					Kami tidak memastikan bahwa informasi di situs web ini benar, kami
					tidak menjamin kelengkapan atau keakuratannya; kami juga tidak
					berjanji untuk memastikan bahwa situs web tetap tersedia atau materi
					di situs web selalu diperbarui.
				</p>
			)}

			<h3>
				{english ? <strong>Disclaimer</strong> : <strong>Peringatan</strong>}
			</h3>

			{english ? (
				<p>
					To the maximum extent permitted by applicable law, we exclude all
					representations, warranties and conditions relating to our website and
					the use of this website. Nothing in this disclaimer will:
				</p>
			) : (
				<p>
					Selama diizinkan oleh hukum yang berlaku, kami mengecualikan semua
					pernyataan, jaminan, dan ketentuan yang berkaitan dengan situs web
					kami dan penggunaan situs web ini. Tidak ada dalam peringatan ini yang
					akan:
				</p>
			)}

			{english ? (
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							limit or exclude our or your liability for death or personal
							injury;
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							limit or exclude our or your liability for fraud or fraudulent
							misrepresentation;
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							limit any of our or your liabilities in any way that is not
							permitted under applicable law; or
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							exclude any of our or your liabilities that may not be excluded
							under applicable law.
						</h1>
					</div>
				</div>
			) : (
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							membatasi atau meniadakan tanggung jawab kami atau Anda atas
							kematian atau cedera pribadi;
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							membatasi atau mediadakan tanggung jawab kami atau Anda atas
							penipuan atau misrepresentasi yang curang;
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							membatasi atau meniadakan kewajiban kami atau Anda dengan cara apa
							pun yang tidak diizinkan menurut hukum yang berlaku; atau
						</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>-</div>
						<h1>
							mengecualikan salah satu kewajiban kami atau Anda yang mungkin
							tidak dikecualikan menurut hukum yang berlaku.
						</h1>
					</div>
				</div>
			)}

			{english ? (
				<p>
					The limitations and prohibitions of liability set in this Section and
					elsewhere in this disclaimer:
				</p>
			) : (
				<p>
					Batasan dan larangan tanggung jawab yang diatur dalam Bagian ini dan
					di tempat lain dalam peringatan ini:
				</p>
			)}

			{english ? (
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row gap-x-3">
						<div>(a)</div>
						<h1>are subject to the preceding paragraph; and</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>(b)</div>
						<h1>
							govern all liabilities arising under the disclaimer, including
							liabilities arising in contract, in tort and for breach of
							statutory duty.
						</h1>
					</div>
				</div>
			) : (
				<div className="flex flex-col gap-y-2">
					<div className="flex flex-row gap-x-3">
						<div>(a)</div>
						<h1>mengikuti pada paragraf sebelumnya; dan</h1>
					</div>
					<div className="flex flex-row gap-x-3">
						<div>(b)</div>
						<h1>
							mengatur semua kewajiban yang timbul berdasarkan disclaimer,
							termasuk kewajiban yang timbul dalam kontrak, dalam perbuatan
							melawan hukum dan untuk pelanggaran kewajiban hukum.
						</h1>
					</div>
				</div>
			)}

			{english ? (
				<p>
					As long as the website and the information and services on the website
					are provided free of charge, we will not be liable for any loss or
					damage of any nature.
				</p>
			) : (
				<p>
					Selama situs web dan informasi serta layanan di situs web disediakan
					secara gratis, kami tidak akan bertanggung jawab atas kehilangan atau
					kerusakan apa pun.
				</p>
			)}
			<div className="mb-10" />
		</div>
	);
}

export default Terms;
