import { useEffect, useState } from "react";

import AccountInformationSummary from "./Account/AccountInformationSummary.js";
import CreateFinish from "./CreateRevalidate/SellInfo/Finish";
import CreateLoading from "./CreateRevalidate/SellInfo/Loading.js";
import CreatePopup from "./CreateRevalidate/Popup.js";
import EditFinish from "./EditRevalidate/Finish";
import EditLoading from "./EditRevalidate/Loading";
import EditPopup from "./EditRevalidate/Popup";
import PriceSummary from "./Price/PriceSummary.js";
import Revalidate from "./EditRevalidate/Revalidate.js";
import SellInfo from "./CreateRevalidate/SellInfo/SellInfo.js";
import SellerInformationSummary from "./Seller/SellerInformationSummary.js";
import SkinInformationSummary from "./Skins/SkinInformationSummary.js";
import SummaryButtons from "./Buttons/SummaryButtons.js";

export default function Summary({ ...props }) {
	const [sellInfoPopup, setSellInfoPopup] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [submitFinish, setSubmitFinish] = useState(false);
	const [revalidate, setRevalidate] = useState(false);
	const [editPassword, setEditPassword] = useState(null);
	const new_props = {
		...props,
		sellInfoPopup,
		setSellInfoPopup,
		submitLoading,
		setSubmitLoading,
		submitFinish,
		setSubmitFinish,
		editPassword,
		setEditPassword,
		revalidate,
		setRevalidate,
	};

	return (
		<>
			{props.create && <CreateRevalidate {...new_props} />}
			{props.update && <UpdateRevalidate {...new_props} />}
			<div className="mx-10 sm:mx-14 xl:mx-80 text-valoblack">
				<div className="flex justify-center my-10">
					<h1 className="text-4xl font-bold">Ringkasan</h1>
				</div>
				<SellerInformationSummary {...new_props} />
				<AccountInformationSummary {...new_props} />
				<SkinInformationSummary {...new_props} />
				<PriceSummary {...new_props} />
				<SummaryButtons {...new_props} />
			</div>
		</>
	);
}

const UpdateRevalidate = ({ ...props }) => {
	useEffect(() => {
		if (props.revalidate) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
		return () => {
			document.body.style.overflow = "auto";
		};
	}, [props.revalidate]);

	return (
		<>
			{props.revalidate && (
				<EditPopup setPopup={props.setRevalidate}>
					<Revalidate {...props} />
				</EditPopup>
			)}
			{props.submitLoading && (
				<EditPopup>
					<EditLoading {...props} />
				</EditPopup>
			)}
			{props.submitFinish && (
				<EditPopup>
					<EditFinish {...props} />
				</EditPopup>
			)}
		</>
	);
};

const CreateRevalidate = ({ ...props }) => {
	useEffect(() => {
		if (props.sellInfoPopup) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
		return () => {
			document.body.style.overflow = "auto";
		};
	}, [props.sellInfoPopup]);

	return (
		<>
			{props.sellInfoPopup && (
				<div className="w-full">
					<CreatePopup closable={true} {...props}>
						<SellInfo {...props} />
					</CreatePopup>
				</div>
			)}
			{props.submitLoading && (
				<div className="w-full">
					<CreatePopup {...props}>
						<CreateLoading {...props} />
					</CreatePopup>
				</div>
			)}
			{props.submitFinish && (
				<div className="w-full">
					<CreatePopup {...props}>
						<CreateFinish {...props} />
					</CreatePopup>
				</div>
			)}
		</>
	);
};
