function SkinDisplay({ skin, setVideoPopup, setVideoSrc }) {
	return (
		<button
			onClick={() => {
				setVideoPopup(true);
				setVideoSrc(skin.videoSrc);
			}}
			className="transition-all duration-300 text-black hover:text-white relative group w-full flex flex-col gap-y-5 items-center justify-center p-5 border border-valowhite4 border-collapse"
		>
			<div className="z-30 absolute right-0 left-0 top-0 bottom-0 flex">
				<div className="transition-all duration-300 group-hover:w-full w-0 bg-redButton"></div>
			</div>
			<div className="z-40 w-full flex justify-center">
				<img
					src={skin.imageSrc}
					alt="skinImageSrc"
					className="h-12 object-scale-down"
				/>
			</div>
			<h1 className="z-40 text-base font-semibold">{skin.displayName}</h1>
		</button>
	);
}

export default SkinDisplay;
