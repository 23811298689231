import { axios, axiosWithCredentials } from "../../constants";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AccountDetail from "./AccountDetail/AccountDetail";
import AgentList from "./AccountDetail/Agents/SmallScreen/AgentList";
import BuyButton from "./BuyButton/BuyButton";
import ListingPrice from "./AccountDetail/ListingPrice/ListingPrice";
import NavLink from "./NavLink/NavLink";
import Screenshot from "./Screenshot/Screenshot";
import SkinList from "./SkinList/SkinList";
import SmallAgentList from "./AccountDetail/Agents/BigScreen/AgentList";
import VideoPopup from "./VideoPopup/VideoPopup";
import { useQuery } from "react-query";

export default function Listing() {
	let params = useParams();
	const navigate = useNavigate();
	const listing = useQuery(["listing", params.listing_id], async () => {
		return await axios.get(`get_listing_detail/${params.listing_id}/`);
	});
	const status = useQuery(["status", params.listing_id], async () => {
		return await axiosWithCredentials.get(
			`can_open_buy_page/${params.listing_id}/`
		);
	});
	const [show, setShow] = useState(false);
	const [videoPopup, setVideoPopup] = useState(false);
	const [videoSrc, setVideoSrc] = useState("");

	const props = {
		navigate,
		params,
		listing,
		status,
		show,
		setShow,
		videoPopup,
		setVideoPopup,
		videoSrc,
		setVideoSrc,
	};

	useEffect(() => {
		if (videoPopup) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
		return () => {
			document.body.style.overflow = "auto";
		};
	}, [videoPopup]);

	return (
		<div>
			{videoPopup && <VideoPopup {...props} videoSrc={videoSrc} />}
			<div className="p-0 pb-10 sm:pb-20 lg:p-14 xl:p-20">
				<NavLink {...props} />
				<div className="w-full flex flex-col gap-y-4 sm:gap-y-10 md:gap-y-14">
					<div className="w-full flex flex-col lg:flex-row items-center md:gap-x-10 xl:gap-x-20">
						<div className="lg:w-3/5 2xl:w-1/2">
							<Screenshot {...props} />
						</div>
						<div className="px-5 sm:px-20 lg:px-0 w-full lg:w-2/5 2xl:w-1/2 flex flex-col justify-between gap-y-5 md:gap-y-5 xl:gap-y-10">
							<div className="w-full flex flex-col lg:flex-row lg:justify-between lg:items-center gap-x-10 justify-start">
								<ListingPrice listing={listing} />
								<BuyButton {...props} />
							</div>
							<div className="flex w-full">
								<div className="lg:w-full hidden lg:block">
									<AccountDetail {...props} />
								</div>
							</div>
						</div>
					</div>
					<div className="px-5 sm:px-20 lg:px-0">
						<SkinList {...props} />
					</div>
					<div className="lg:hidden px-5 sm:px-20">
						<AccountDetail {...props} />
					</div>
					<div className="px-5 sm:px-20 lg:hidden">
						<AgentList {...props} />
					</div>
					<div className="hidden lg:flex w-full">
						<SmallAgentList listing={listing} />
					</div>
				</div>
			</div>
		</div>
	);
}
