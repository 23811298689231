import { Reformat } from "../PriceInRupiah/Price";

export default function Price({ listing }) {
	return (
		<div className="h-full w-full flex flex-col justify-center py-5">
			{listing.total_price > listing.discounted_price && (
				<h1 className="mx-5 text-sm sm:text-md font-semibold text-valogray">
					<s>Rp{Reformat(listing.total_price)}</s>
				</h1>
			)}
			<h1 className="mx-5 text-lg xl:text-xl 2xl:text-2xl font-semibold">
				Rp{Reformat(listing.discounted_price)}
			</h1>
		</div>
	);
}
