import EditListing from "./EditListing";
import { axios } from "../../constants";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

export default function Index() {
	let params = useParams();
	const fetchListing = async () => {
		const resp = await axios.get("get_listing_detail/" + params.listing_id);
		return resp;
	};
	const listing = useQuery(["listing", params.listing_id], fetchListing);
	const navigate = useNavigate();

	if (listing.isLoading) return "Loading...";
	if (listing.isError) navigate(`/error?err=${listing.error.message}`);
	if (listing.data.data.password !== params.password) {
		return (
			<div className="w-full h-screen max-h-screen flex flex-wrap">
				<div className="w-full h-[calc(100%-100px)] flex flex-wrap justify-center items-center">
					<div className="w-full sm:w-4/5 md:w-2/3 lg:w-1/4">
						<img src="/jettno.png" alt="denied" className="w-full rounded-xl" />
						<h1 className="w-full text-center text-2xl font-semibold">
							Anda tidak diizinkan untuk mengakses halaman ini
						</h1>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="text-valoblack">
			<EditListing listing={listing.data.data} />
		</div>
	);
}
