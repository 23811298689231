function Loading() {
	return (
		<div className="w-full h-full flex flex-col justify-center items-center">
			<img src="/jettkawai.png" alt="cute jett" className="animate-bounce" />
			<h1 className="text-3xl text-white">Akun Anda sedang diproses</h1>
		</div>
	);
}

export default Loading;
