function Loading() {
	return (
		<div className="w-full flex flex-col gap-y-3 p-6">
			<div className="w-full flex justify-start">
				<h1 className="text-md font-medium">Cari skin di halaman ini</h1>
			</div>
			<div className="w-full flex justify-start">
				<div className="w-full h-10 bg-slate-400 rounded-xl animate-pulse"></div>
			</div>
		</div>
	);
}

export default Loading;
