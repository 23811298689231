import Loading from "./Loading";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

export default function SkinFilter({
	navigate,
	skins,
	searchParams,
	setSearchParams,
}) {
	if (skins.isLoading) return <Loading />;
	if (skins.isError) navigate(`/error?err=${skins.error.message}`);

	const options = skins.data.data.data.map((skin) => {
		return {
			value: skin.uuid,
			label: skin.displayName,
			imageSrc: skin.imageSrc,
		};
	});

	return (
		<div className="w-full flex flex-col gap-y-3 p-6">
			<div className="w-full flex justify-start">
				<h1 className="text-md font-medium">Cari skin di halaman ini</h1>
			</div>
			<div className="w-full flex justify-start">
				<Select
					components={animatedComponents}
					options={options}
					isMulti
					value={options.filter((option) =>
						(searchParams.get("q") ? searchParams.get("q") : []).includes(
							option.label
						)
					)}
					onChange={(e) => {
						if (e.length === 0) {
							setSearchParams([...searchParams].filter((f) => f[0] !== "q"));
						} else {
							setSearchParams({
								...Object.fromEntries([...searchParams]),
								q: JSON.stringify(e.map((elm) => elm.label)),
							});
						}
					}}
					className="w-full basic-multi-select"
					placeholder="Cari skin"
					formatOptionLabel={(skin) => {
						return (
							<>
								{skin.imageSrc && (
									<div className="flex flex-row items-center text-sm">
										<img src={skin.imageSrc} alt="skinImage" className="h-5" />
										<h1 className="ml-2">{skin.label}</h1>
									</div>
								)}
							</>
						);
					}}
				/>
			</div>
		</div>
	);
}
