import LeftArrow from "../Listings/LeftArrow";
import RightArrow from "../Listings/RightArrow";

function Loading({ index, setIndex, listingPerPage }) {
	return (
		<div className="bg-discountBg py-5 sm:py-20 flex justify-center">
			<div className="mx-5 sm:mx-20 w-5/6">
				<div className="w-full flex flex-col">
					<h1 className="text-2xl md:text-4xl text-left text-valoblack font-bold">
						Akun Valorant Diskon
					</h1>
					<div
						className={`relative flex flex-row flex-wrap justify-center gap-x-4`}
					>
						<LeftArrow disabled={true} index={index} setIndex={setIndex} />
						<RightArrow disabled={true} index={index} setIndex={setIndex} />
						{[...Array(listingPerPage).keys()].map((x) => {
							return (
								<button
									disabled={true}
									key={x}
									className="w-full md:w-[45%] xl:w-[30%] h-[350px] drop-shadow-xl my-10"
								>
									<div className="w-full h-full rounded-xl bg-slate-400 animate-pulse"></div>
								</button>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Loading;
