import ListingSummary from "../../../components/ListingSummary/ListingSummary";
import Loading from "./Loading";

export default function ExploreListings({ codeFilter, listingList, navigate }) {
	if (listingList.isLoading) return <Loading />;
	if (listingList.isError) navigate(`/error?err=${listingList.error.message}`);

	return (
		<div className="grid gap-x-5 place-content-center grid-cols-1 sm:grid-cols-2">
			{listingList.data.data.data
				.filter(
					(listing) =>
						listing.listing_id
							.toLowerCase()
							.indexOf(codeFilter.toLowerCase()) !== -1
				)
				.map((listing) => {
					return (
						<button
							onClick={() => {
								navigate("../../listing/" + listing.listing_id);
							}}
							key={listing.listing_id}
							className="relative bg-white drop-shadow-xl my-5 rounded-xl"
						>
							<ListingSummary listing={listing}></ListingSummary>
						</button>
					);
				})}
		</div>
	);
}
