import ListingForm from "../../components/ListingForm/ListingForm";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function EditListing({ listing }) {
	const navigate = useNavigate();
	let params = useParams();

	if (listing.isLoading) return "Loading...";
	if (listing.isError) navigate(`/error?err=${listing.error.message}`);
	if (listing.password !== params.password) {
		return <WrongPassword />;
	}

	const initData = {
		name: listing.name,
		email: listing.email,
		whatsapp: listing.whatsapp,
		lineID: listing.line,
		bca: listing.bca,
		lockedAgents: listing.locked_agents,
		rank: listing.rank,
		changeNicknameAvailable: listing.change_nickname,
		changeNicknameAvailableIn: listing.change_nickname_available_in,
		emailVerified: listing.email_verification,
		level: listing.level,
		valorantPoints: listing.valorant_points,
		radianitePoints: listing.radianite_points,
		additionalDescription: listing.additional_description,
		ownedSkins: listing.skins,
		screenshot: listing.screenshot_id,
		screenshotImageSrc: listing.screenshotImageSrc,
		discount: listing.discount,
	};

	return <ListingForm initData={initData} update={true} />;
}

const WrongPassword = () => {
	return (
		<div className="w-full h-screen max-h-screen flex flex-wrap">
			<div className="w-full h-[calc(100%-100px)] flex flex-wrap justify-center items-center">
				<div className="w-full sm:w-4/5 md:w-2/3 lg:w-1/4">
					<img src="/jettno.png" alt="denied" className="w-full rounded-xl" />
					<h1 className="w-full text-center text-2xl font-semibold">
						Anda tidak diizinkan untuk mengakses halaman ini
					</h1>
				</div>
			</div>
		</div>
	);
};
