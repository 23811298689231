import Loading from "./Loading";
import SkinDisplay from "../../SkinDisplay/SkinDisplay";
import { useWindowSize } from "../../../../hooks/useWindowSize";

export default function Top3({
	navigate,
	listing,
	setVideoPopup,
	setVideoSrc,
}) {
	const size = useWindowSize();

	if (listing.isLoading) return <Loading />;
	if (listing.isError) navigate(`/error?err=${listing.error.message}`);

	const top3 = listing.data.data.skins.slice(0, size.width >= 640 ? 3 : 2);
	return (
		<div className="grid grid-cols-2 sm:grid-cols-3 lg:hidden">
			{top3.map((skin) => {
				return (
					<SkinDisplay
						key={skin.uuid}
						skin={skin}
						setVideoPopup={setVideoPopup}
						setVideoSrc={setVideoSrc}
					/>
				);
			})}
		</div>
	);
}
