import Button from "../../../Button/TertiaryButton";
import ListingSlider from "../../../ListingSlider/ListingSlider";
import { useNavigate } from "react-router-dom";

function Listings({
	listings,
	bundleImageSrc,
	bundleDisplayName,
	bundleUuid,
	listingPerPage,
}) {
	const navigate = useNavigate();
	let splitListings = [];
	for (let i = 0; i < listings.length; i += listingPerPage) {
		let curListings = [];
		for (let j = i; j < Math.min(i + listingPerPage, listings.length); j++) {
			curListings.push(listings[j]);
		}
		splitListings.push(curListings);
	}
	return (
		<>
			<div className="w-full flex flex-col items-center">
				<div className="flex flex-row items-center gap-x-3 sm:gap-x-5 w-5/6 py-5 sm:py-10">
					<h1 className="text-base sm:text-xl md:text-4xl self-start font-extrabold">
						{bundleDisplayName} Series
					</h1>
					<div className="text-xs sm:text-base md:text-xl">
						<Button
							label="Lihat Semua"
							onClick={() =>
								navigate(`/explore?bd=${JSON.stringify([bundleUuid])}`)
							}
						/>
					</div>
				</div>
				<div className="w-5/6 flex flex-col justify-center items-center overflow-hidden max-h-[400px] rounded-xl sm:rounded-3xl">
					<img
						src={bundleImageSrc}
						alt="bundleImage"
						className="w-full rounded-xl sm:rounded-2xl xl:rounded-3xl"
					/>
				</div>
				<div className="w-5/6 my-10">
					<ListingSlider splitListings={splitListings} />
				</div>
			</div>
		</>
	);
}

export default Listings;
