import Screenshot from "../../Information/Screenshot/Screenshot";

export default function AccountInformationSummary({
	lockedAgents,
	rank,
	changeNicknameAvailable,
	changeNicknameAvailableIn,
	emailVerified,
	level,
	valorantPoints,
	radianitePoints,
	additionalDescription,
	screenshotImageSrc,
}) {
	return (
		<div className="mb-20 flex flex-col gap-y-5">
			<h1 className="text-xl md:text-3xl text-left font-bold text-valoblack">
				Informasi Akun
			</h1>
			<div className="grid grid-cols-4 gap-y-4 gap-x-4">
				<div className="col-span-2 sm:col-span-1">
					Agent yang belum dimiliki
				</div>
				<div className="col-span-2 sm:col-span-3 flex flex-wrap">
					{lockedAgents.map((agent) => {
						return (
							<img
								key={agent.uuid}
								src={agent.imageSrc}
								alt="agentDisplay"
								className="w-16 h-16 rounded-full"
							/>
						);
					})}
				</div>
				<div className="col-span-2 sm:col-span-1">Rank</div>
				<div className="col-span-2 sm:col-span-3">
					<div className="flex justify-start items-center">
						<img
							src={rank.imageSrc}
							alt="rankDisplay"
							className="w-[75px] h-[75px]"
						/>
					</div>
				</div>
				<Screenshot screenshotImageSrc={screenshotImageSrc} />
				<div className="col-span-2 sm:col-span-1">Bisa Change Nickname</div>
				<div className="col-span-2 sm:col-span-3 flex">
					<div className="">{changeNicknameAvailable ? "Ya" : "Tidak"}</div>
					<div className="mx-4">
						{!changeNicknameAvailable &&
							`Bisa ganti dalam ${changeNicknameAvailableIn} hari`}
					</div>
				</div>
				<div className="col-span-2 sm:col-span-1">
					Email sudah terverifikasi
				</div>
				<div className="col-span-2 sm:col-span-3">
					{emailVerified ? "Ya" : "Tidak"}
				</div>
				{level !== null && level !== "" && (
					<div className="col-span-2 sm:col-span-1">Level</div>
				)}
				{level !== null && level !== "" && (
					<div className="col-span-2 sm:col-span-3">{level}</div>
				)}
				{valorantPoints !== null && valorantPoints !== "" && (
					<div className="col-span-2 sm:col-span-1">VP</div>
				)}
				{valorantPoints !== null && valorantPoints !== "" && (
					<div className="col-span-2 sm:col-span-3">{valorantPoints}</div>
				)}
				{radianitePoints !== null && radianitePoints !== "" && (
					<div className="col-span-2 sm:col-span-1">RP</div>
				)}
				{radianitePoints !== null && radianitePoints !== "" && (
					<div className="col-span-2 sm:col-span-3">{radianitePoints}</div>
				)}
				{additionalDescription !== "" && (
					<div className="col-span-2 sm:col-span-1">Deskripsi Tambahan</div>
				)}
				{additionalDescription !== "" && (
					<div className="col-span-2 sm:col-span-3">
						<span className="whitespace-pre">{additionalDescription}</span>
					</div>
				)}
			</div>
		</div>
	);
}
