export default function Image({ listing }) {
	return (
		<div className="w-full flex justify-center">
			<img
				src={listing.imageSrc}
				alt="listing"
				className="w-full rounded-tl-xl rounded-tr-xl"
			/>
		</div>
	);
}
